import {React, useCallback} from 'react';
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from '../components/Misc/LoadingSpinner'
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import '../i18n';
import FileDropZone from "../components/FileAdd/FileDropZone";
import { InfoCircleFill } from 'react-bootstrap-icons';
import DocsEmbed from "../components/GoogleDocs/DocsEmbed";
import ContractCheck from '../components/Contract/ContractCheck';

function ContractPage() {
  const [nextPage, setNextPage] = useState(false)
  const [contract, setContract] = useState(null);
  const [omatTiedotShow, setOmatTiedotShow] = useState(false);
  const { t, i18n } = useTranslation();
  const [pickedFile, setpickedFile] = useState();
  const [sendLoader, setSendLoader] = useState(true);
  const [imgUrl, setImgUrl] = useState(null);



const notifyAddError = () =>
  toast.error("Virhe tiedoston lisäyksessä: Yritä uudelleen tai ota yhteyttä palveluasiantuntijaasi.", {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

// let userData = JSON.parse(sessionStorage.getItem("userData"));
  useEffect(() => {

  }, []);

  const uploadFiles = (files) => {
    setSendLoader(false);
   console.log("????")
 

  

    const formData = new FormData();
    formData.append("file", pickedFile);


    const response = fetch("/api/ContractParser/" + sessionStorage.getItem("id"), {
      method: "POST",
      body: formData,
      headers: {
       
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        //console.log(data);
        setContract(JSON.parse(data))
        setSendLoader(true);

        setNextPage(true)
        //notifyAddSuccess();
      }).catch((error) => {
        console.log('error: ' + error);
        notifyAddError();
      });
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      const reader = new FileReader();
      
      reader.readAsDataURL(file);

      let imgSrc = URL.createObjectURL(file);
    
      setImgUrl(imgSrc)

      setpickedFile(file);
      return file;
    });
  }, []);

  console.log(contract ? contract : "eipä")
  //console.log("asddsa", JSON.parse(sessionStorage.getItem("userData")));

if(nextPage){
  return(
   
  <div id="tableContainerRekry" style={{display:'flex', flexDirection:'column'}}>
     <div>
    <button onClick={() => setNextPage(false)} className="btn btn-default"   style={{
                marginTop: "0",
                backgroundColor: "#7DB5DA",
                paddingTop: "0.25rem",
                marginBottom: "2vh",
                float: 'left'
              }}>Takaisin</button>
    </div>
    <div style={{margin: '0vh 3vh 0vh 3vh'}}>
  <ContractCheck
   contract = {contract} setNextPage={setNextPage} pickedFile={pickedFile}
  />
  </div>
  </div>
  )
}

 else return(
  <div id="tableContainerRekry">
  <div className="percentH2Div">
      <div id="rekryProgressPage" className="progressBall">
        <span className="progress-value" id="rekry-progress-value"></span>
      </div>
      <h1  className="omatTiedotHeader">
       {t('contract.title')} 
      </h1>
      <div style={{placeSelf:'center', paddingLeft:'6px', height:'100%', marginBottom:'-1.4vh'}}> 
        <a
          className="header-info-link"
          onClick={() => setOmatTiedotShow(true)}
        >
          <InfoCircleFill color="black" size="18" />
        </a>
      </div>{" "}
      {omatTiedotShow ? (
        <DocsEmbed
          setOmatTiedotShow={setOmatTiedotShow}
          show={omatTiedotShow}
          elementId={"_tli8haywgedf"}
        />
      ) : (
        <></>
      )}
      <div id="emptyHeadDiv">


      </div>
    </div>

 <div className="tableView">
        <div id="cvDiv">
          <div style={{width:'50%'}} className="drop-zone">
            <FileDropZone maxFiles={1} onDrop={onDrop} img={imgUrl}/>
          </div>
          <div style={{ marginTop: "2rem" }}>
            <div className="selectLangType">

       

              {sendLoader ? 
              (
                <button
                className="btn btn-default"
                id="submitFile"
                style={{
                  marginTop: "0",
                  backgroundColor: "#7DB5DA",
                  paddingTop: "0.25rem",
                }}
                onClick={() => uploadFiles(pickedFile)}
              >
                {t('contract.send')}
              </button>
              )
               : 
              (
                <button
                className="btn btn-default"
                id="submitFile"
                style={{
                  marginTop: "0",
                  backgroundColor: "#7DB5DA",
                  paddingTop: "0.25rem",
                }}
              >
                <LoadingSpinner type={"small"}/>
              </button>
              )}
              
            </div>
            <br></br>
                <h4>{t('contract.or')}</h4>
                <br></br>
                <button onClick={() => setNextPage(true)} className="btn btn-default"   style={{
                  marginTop: "0",
                  backgroundColor: "#7DB5DA",
                  paddingTop: "0.25rem",
                }}>{t('contract.continue')}</button>
           
          </div>
        </div>
      </div>

    </div>
 )

 }
export default ContractPage;
