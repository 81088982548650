import LoadingSpinner from "../Misc/LoadingSpinner";
import { Button, Modal } from "react-bootstrap";

function SendButton({ loading }) {
  if (!loading) {
    return (
      <Button variant="primary" type="submit">
        Lähetä
      </Button>
    );
  } else {
    return (
      <Button variant="primary">
        <LoadingSpinner type={"small"} />
      </Button>
    );
  }
}
export default SendButton;
