import { React, useEffect, useState } from "react";
import SingleForm from "../components/Forms/SingleForm";
import { CircularProgress } from "@mui/material";

function Forms() {
  const [forms, setForms] = useState();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState("");

  const getForms = () => {
    setLoading(true);
    let obj = {
      projectId: JSON.parse(sessionStorage.getItem("userData")).projectId.value,
      userId: JSON.parse(sessionStorage.getItem("userData")).searchRecId.value,
    };
    const response = fetch(
      "/api/user/getForms/" + sessionStorage.getItem("id"),
      {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        setForms(data.projectForms);

        setLoading(false);
      })
      .catch((error) => {
        console.log("error: " + error);
        setLoading(false);
      });
  };

  async function getUserData(action) {
    await fetch("/api/user/userdata/" + sessionStorage.getItem("id") + "/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        //console.log(response)
        if (response.ok) {
          return response.json();
        }
      })
      .then(async function (data) {
        let parsedData = JSON.parse(data);

        sessionStorage.setItem("name", parsedData?.recordFirstName?.value + " " + parsedData?.recordLastName?.value);
        sessionStorage.setItem("birthDate", parsedData?.birthDate?.value)
        sessionStorage.setItem("userData", data);
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  }

  useEffect(() => {
    if (sessionStorage.getItem("forms")) {
      setForms(JSON.parse(sessionStorage.getItem("forms")));
    } else {
      getForms();
    }
  }, []);

  return (
    <>
      <div id="tableContainer">
        <h3 className="fbH3">Lomakkeet</h3>
        <div>
          {forms && !forms.error ? (
            <>
              {forms.map((item, index) => (
                <>
                  {(item.onlyOneReturn && !item.answers) ||
                  !item.onlyOneReturn ? (
                    <>
                      <SingleForm
                        form={item}
                        refresh={getForms}
                        getUserData={getUserData}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ))}
            </>
          ) : forms && forms.error ? (
            <>
              <p>Sinulle ei ole vielä lisätty lomakkeita.</p>
            </>
          ) : (
            <>
              <CircularProgress />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Forms;
