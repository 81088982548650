import { React, useState } from "react";
import { Modal } from "react-bootstrap";
import LoadingSpinner from "./Misc/LoadingSpinner";
import { useTranslation } from "react-i18next";
import "../i18n";

function DataConsent({ show, handleCloseModal, notifyError, notifySuccess }) {
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(null);
  const { t, i18n } = useTranslation();

  function setDataPermitFunction() {
    let orig = JSON.parse(sessionStorage.getItem("userData"));
    orig.dataPermit.value = value;
    sessionStorage.setItem("userData", JSON.stringify(orig));
  }

  function handleSubmit() {
    setLoading(true);
    let obj = { value };
    const response = fetch(
      "/api/misc/permitedit/" + sessionStorage.getItem("id"),
      {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then(function (response) {
        return response.text();
      })
      .then(function (data) {
        setLoading(false);
        notifySuccess();
        handleCloseModal();
        setDataPermitFunction();
      })
      .catch((error) => {
        console.log("error: " + error);
        setLoading(false);
        notifyError();
      });
  }

  return (
    <Modal
      size="lg"
      className="deviceModal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={handleCloseModal}
    >
      <Modal.Header style={{ alignSelf: "center" }}>
        <Modal.Title>{t("dataconsent.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p id="guideText" className="guideText">
          {t("dataconsent.p1")}
          Käsittelemme tietojasi{" "}
          <a href="https://www.arffman.fi/tietosuoja/" target="_blank">
            {" "}
            {t("dataconsent.p2")}
          </a>{" "}
          {t("dataconsent.p3")}
          <br></br>
          {t("dataconsent.p4")}
          <br></br>
          {t("dataconsent.p45")}
          <br></br>
          <strong>
            {t("dataconsent.p5")}

            <br></br>
            {t("dataconsent.p6")}
            <br></br>
          </strong>
          {t("dataconsent.p7")}
        </p>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <select onChange={(e) => setValue(e.target.value)}>
            <option value="">{t("dataconsent.select1")}</option>
            <option value="Kyllä">{t("dataconsent.select2")}</option>
            <option value="Ei">{t("dataconsent.select3")}</option>
          </select>
        </div>
        <br></br>
        {loading ? (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button className="saveData-button">
                <LoadingSpinner type={"small"} />
              </button>
            </div>
          </>
        ) : (
          <>
            {value === "Kyllä" || value === "Ei" ? (
              <>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button className="saveData-button" onClick={handleSubmit}>
                    {t("save")}
                  </button>
                </div>
              </>
            ) : (
              <>
                <div style={{ height: "3.5vh" }}></div>
              </>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

export default DataConsent;
