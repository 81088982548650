import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import arffman from "../images/arffman.png";
// Replace with your base64 string
const arffmanBase64 = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAA...";

const ParticipationCertificate = ({ city, expert, service, start, end }) => {
  const styles = StyleSheet.create({
    page: {
      padding: 40,
      fontSize: 12,
      fontFamily: "Helvetica",
    },
    logo: {
      width: 120,
      heigth: 120,
      marginBottom: 100,
      alignSelf: "center",
    },
    header: {
      fontSize: 18,
      textAlign: "center",
      marginBottom: 30,
      fontWeight: "bold",
    },
    bodyText: {
      marginBottom: 10,
      textAlign: "justify",
    },
    lineBreak: {
      marginBottom: 20,
    },
    footer: {
      position: "absolute",
      bottom: 40,
      left: 40,
      fontSize: 10,
      textAlign: "left",
    },
    signature: {
      marginTop: 50,
    },
  });

  let bday = sessionStorage.getItem("birthDate");

  if (bday) {
    // Convert the stored string to a Date object
    const bdayDate = new Date(bday);

    // Format the date using toLocaleDateString
    bday = bdayDate.toLocaleDateString("fi-FI");
  }

  let startFormatted = start;
  if (startFormatted) {
    startFormatted = new Date(startFormatted).toLocaleDateString("fi-FI");
  }
  let endFormatted = end;
  if (endFormatted) {
    endFormatted = new Date(endFormatted).toLocaleDateString("fi-FI");
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image style={styles.logo} src={arffman} />
        <Text style={styles.header}>OSALLISTUMISTODISTUS</Text>
        <Text style={styles.bodyText}>
          {sessionStorage.getItem("name")} (s. {bday}) opiskelee Arffman Finland
          Oy:n järjestämässä koulutuksessa {service}. Koulutuksen kokonaiskesto
          on {startFormatted} - {endFormatted}.
        </Text>
        <View style={styles.lineBreak} />
        <Text style={styles.bodyText}>
          Koulutus on kokopäiväistä työvoimakoulutusta.
        </Text>
        <View style={styles.lineBreak} />
        <Text style={styles.bodyText}>
          {city || "Rovaniemi"} {new Date().toLocaleDateString("fi-FI")}
          {"\n"}
          ARFFMAN FINLAND OY{"\n"}
        </Text>
        <Image
          style={{
            ...styles.logo,
            alignSelf: "flex-end",
            marginBottom: "10px",
            heigth: "150px",
            width: "150px",
          }}
          src={arffman}
        />
        <Text style={styles.bodyText}>
          {expert}
          {"\n"}
          Kouluttaja
        </Text>

        <Text style={styles.footer}>
          Arffman Finland Oy{"\n"}
          044 733 1500, toimisto@edu.arffman.fi{"\n"}
          Y-tunnus 2060633-3
        </Text>
      </Page>
    </Document>
  );
};

export default ParticipationCertificate;
