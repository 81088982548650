import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

//import Backend from 'i18next-xhr-backend';
//import LanguageDetector from 'i18next-browser-languagedetector';
import fi from './locales/fi/fi.json'
import en from './locales/en/en.json'
import se from './locales/se/se.json'
import ua from './locales/ua/ua.json'
import ru from './locales/ru/ru.json'

i18n
  ///.use(Backend)
  //.use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
        en: {
          translation: en,
        },
        fi: {
          translation: fi,
        },
        se: {
          translation: se
        },
        ua: {
          translation: ua
        },
        ru: {
          translation: ru
        }
    },
    fallbackLng: 'fi',
    debug: true,

    interpolation: {
      escapeValue: false,
    }
  });
export default i18n;