import React, { useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Button } from "react-bootstrap";
import LoadingSpinner from "../Misc/LoadingSpinner";

  function CropImg({img, setPickedFile, handleSubmit}) {
  const [cropper, setCropper] = useState<any>();
  const [saveLoading, setSaveLoading] = useState(false);


  const getCropData = () => {
    setSaveLoading(true)
    if (typeof cropper !== "undefined") {
      cropper.getCroppedCanvas().toBlob(blob => {
        console.log('cropped', blob);
        let parts = [blob];
        let randomizer = Math.floor(Math.random() * 10000000)
        let file = new File([blob], randomizer.toString(), { type: blob.type });
        setPickedFile(file)
        handleSubmit(file);
        setCropper(null)
      });
      
      
    }
  };

  return (
    <div>
      <div style={{ width: "100%" }}>
        <Cropper
          style={{ height: "50%", width: "100%" }}
          zoomTo={0}
          initialAspectRatio={1}
          preview=".img-preview"
          src={img}
          viewMode={1}
          minCropBoxHeight={80}
          minCropBoxWidth={80}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
          onInitialized={(instance) => {
            setCropper(instance);
          }}
          guides={true}
        />
      </div>
      <div>
        <Button 
        style={{
          backgroundColor: "#7DB5DA",
          color: "black",
          border: "none",
          marginTop: '3rem',
          marginBottom: '-3rem',
        }} onClick={getCropData}
        >
          {saveLoading ? <LoadingSpinner type="small" /> : "Tallenna"}
        
        </Button>
        
      </div>
      <br style={{ clear: "both" }} />
    </div>
  );
};

export default CropImg;
