import { React } from "react";
import { XLg } from "react-bootstrap-icons";

function Textbox({
  userData,
  field,
  handleSubmit,
  editFunction,
  cancelEdit,
  editValue,
  type,
  permission,
}) {
  const DynamicButton = () => {
    if (userData) {
      if (userData[field].name === "Email") {
        return (
          <>
            <button
              className="email-input"
              onClick={(e) => editFunction(userData[field])}
              style={{ background: "none", borderRadius: "8px" }}
            >
              {userData && userData[field].value ? (
                <>
                  {userData[field].value.length > 20 ? (
                    <>{userData[field].value.substring(0, 20)}...</>
                  ) : (
                    <>{userData[field].value}</>
                  )}
                </>
              ) : (
                "-"
              )}
            </button>
          </>
        );
      }


      if (userData[field].name === "Mailing_Street") {
        return (
          <button
            className="email-input"
            onClick={(e) => editFunction(userData[field])}
            style={{ background: "none", borderRadius: "8px" }}
          >
            {userData && userData[field].value ? userData[field].value : "-"}
          </button>
        );
      }
      if (field === "omaKuvaus") {
        return (
          <button
            className="email-input"
            onClick={(e) => editFunction(userData[field])}
            style={{ background: "none", borderRadius: "8px" }}
          >
            {userData && userData[field].value ?<> {userData[field].value.length > 30 ? <> {userData[field].value.substring(0,25)}...</>:<>{userData[field].value}</>} </> : "-"}
          </button>
        );
      }
      else {
        return (
          <button
            onClick={(e) => editFunction(userData[field])}
            style={{ background: "none", borderRadius: "8px" }}
          >
            {userData && userData[field].value ? userData[field].value : "-"}
          </button>
        );
      }
    }
  };

  const DynamicButtonDisabled = () => {
    if (userData) {
      if (userData[field].name === "Email") {
        return (
          <>
            <button
              className="email-input"
              style={{ background: "none", borderRadius: "8px" }}
            >
              {userData && userData[field].value ? (
                <>
                  {userData[field].value.length > 20 ? (
                    <>{userData[field].value.substring(0, 20)}...</>
                  ) : (
                    <>{userData[field].value}</>
                  )}
                </>
              ) : (
                "-"
              )}
            </button>
          </>
        );
      }
      if (userData[field].name === "Mailing_Street") {
        return (
          <button
            className="email-input"
            style={{ background: "none", borderRadius: "8px" }}
          >
            {userData && userData[field].value ? userData[field].value : "-"}
          </button>
        );
      } else {
        return (
          <button
            style={{
              background: "none",
              borderRadius: "8px",
              cursor: "default",
            }}
          >
            {userData && userData[field].value ? userData[field].value : "-"}
          </button>
        );
      }
    }
  };

  return (
    <>
      {userData && userData[field].checked ? (
        <>
          <div style={{ display: "flex" }}>
            <div>
              {type ? (
                <>
                  <textarea
                    type="textarea"
                    style={{height:'200px', width:'200px'}}
                    value={userData[field].value}
                    onChange={(e) => editValue(userData[field], e.target.value)}
                  ></textarea>
                </>
              ) : (
                <>
                  <input
                    type="text"
                    value={userData[field].value}
                    onChange={(e) => editValue(userData[field], e.target.value)}
                  ></input>
                </>
              )}
            </div>

            <div>
              <button
                className="cancelButton"
                onClick={(e) => cancelEdit(userData[field])}
              >
                <XLg size={"20"} />
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          {permission !== "false" ? (
            <>
              <DynamicButton />
            </>
          ) : (
            <>
              <DynamicButtonDisabled />
            </>
          )}
        </>
      )}
    </>
  );
}

export default Textbox;
