import { useState, react } from "react";
import { Button, Table } from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import { PlusCircle } from "react-bootstrap-icons";
import { DashCircle } from "react-bootstrap-icons";

function SingleCollapse({ header, text, pvm, versio }) {
  const [open, setOpen] = useState(false);
  const [clicked, setClicked] = useState(false);

  function handleNotClicked() {
    setOpen(!open)
    setClicked(true)
  }

  
  let lastLogin = sessionStorage.getItem("version")
  //let lastLogin = new Date('2023-01-10')
  console.log('last:', lastLogin, "versio:", versio);

  if(lastLogin < versio) {
    console.log('jep');
    
    return (
      <>
        {open ? (
          <tr onClick={() => handleNotClicked()} className="openWhatsNew">
            <td className="whatsNewTd">
              <DashCircle size={16} style={{marginRight: "0.5rem", marginLeft: "-2rem", marginBottom: "0.2rem"}} />{header} {pvm}
              <Collapse in={open}>
                <div className="whatsNewCollapse">{text}</div>
              </Collapse>
            </td>
          </tr>
        ) : (
          <tr onClick={() => setOpen(!open)} className="closedWhatsNew">
            <td className="whatsNewTd"><PlusCircle size={16} style={{marginRight: "0.5rem", marginLeft: "-2rem", marginBottom: "0.2rem"}} /><strong>{header} {pvm}</strong></td>
          </tr>
        )}
      </>
    );
  }
  else {
    return (
      <>
   
      </>
    );
  }
}

export default SingleCollapse;
