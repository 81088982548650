import React, { useState, useEffect } from 'react';
import { Paper, Typography, Box, Collapse, CircularProgress } from '@mui/material';
import PlusBtn from "../../images/plusJobs-min.png";
import { Tooltip } from "react-tippy";
import MinusBtn from "../../images/minusJobs-min.png";
import BaronaLogo from "../../images/baronalogo.webp";
import ArffmanLogo from "../../images/arffman.png";
import linkIcon from "../../images/share.png";
import StarRatings from "react-star-ratings";
import DocsEmbed from "../GoogleDocs/DocsEmbed";
import { InfoCircleFill } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

const JobAds = ({
    userData,
    rekryData,
}) => {
    const [jobAdsShow, setJobAdsShow] = useState(false);
    const [showCollapse, setShowCollapse] = useState([]);
    const [rankedPostings, setRankedPostings] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (userData && rekryData) {
          if (
            rekryData.professions.value &&
            rekryData.haettavatTeht.value &&
            !rankedPostings
          ) {
            getJobAds();
          }
        }
      }, [rekryData]);
    
      const toggleCollapse = (index, jobAd) => {
        let collapseCopy = [...showCollapse];
        collapseCopy[index] = !collapseCopy[index];
        let obj = { source: jobAd.source, title: jobAd.adTitle };
        if (collapseCopy[index]) {
          fetch("/api/misc/jobAdOpen/" + sessionStorage.getItem("id"), {
            method: "POST",
            body: JSON.stringify(obj),
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then(function (response) {
              return response.text();
            })
            .then(function (data) {});
        }
        setShowCollapse(collapseCopy);
      };
    
      async function getJobAds() {
        let obj = {
          jobsToSearch: rekryData.haettavatTeht.value,
          position: rekryData.asema.value,
          fields: rekryData.hakuAlat.value,
          areas: rekryData.hakuAlueet.value,
          cities: rekryData.paikkaKunnat.value,
        };
    
        await fetch("/api/misc/jobads/" + sessionStorage.getItem("id"), {
          method: "POST",
          body: JSON.stringify(obj),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then(function (data) {
            setRankedPostings(data.aiMatching);
          })
          .catch((error) => {
            console.log("error: " + error);
          });
      }
    
    
      const handleJobAdLogoClick = (jobAd) => {
        let obj = { source: jobAd.source, title: jobAd.adTitle };
        fetch("/api/jobAdLinkClick/" + sessionStorage.getItem("id"), {
          method: "POST",
          body: JSON.stringify(obj),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then(function (response) {
            return response.text();
          })
          .then(function (data) {});
      };

  return (
    <Paper
    elevation={3}
    className="verticalBlock"
    id="workVerticalBlock"
    sx={{ width: "auto" }}
  >
    <div className="headerDiv">
      <Typography sx={{ marginBottom: "15px" }} variant="h4">
        {t("rekry.tyopaikat.title")}{" "}
      </Typography>

      <a className="header-info-link" onClick={() => setJobAdsShow(true)}>
        <InfoCircleFill color="white" size="20" />
      </a>
      {jobAdsShow ? (
        <DocsEmbed
          setOmatTiedotShow={setJobAdsShow}
          show={jobAdsShow}
          elementId={"_gxq444q6an8v"}
        />
      ) : (
        <></>
      )}
    </div>
    {!rankedPostings ? (
      <>
        <ul id="jobAdList" style={{ height: "100%" }}>
          <li className="jobAdItem">
            {t("rekry.tyopaikat.tyopaikatErrorMsg")}
          </li>
        </ul>
      </>
    ) : (
      <>
        {rankedPostings && rankedPostings.length > 0 ? (
          <>
            <ul id="jobAdList">
              {rankedPostings.map((singleAd, i) => (
                <>
                  <li
                    onClick={() => toggleCollapse(i, singleAd)}
                    key={singleAd.id}
                    className="jobAdItem"
                  >
                    <div className="closedAdDiv">
                      <div className="jobTitles">
                        <img
                          alt="Avaa ilmoitus"
                          className="plusAndMinus"
                          src={showCollapse[i] ? MinusBtn : PlusBtn}
                        />
                        <p className="jobAdTitle">{singleAd.adTitle}</p>
                        <Box sx={{ width: "17vw" }}>
                          <Tooltip
                            theme="custom2"
                            size="small"
                            title={
                              "Työpaikan sopivuus sinulle profiilisi perusteella"
                            }
                            position="left"
                            trigger="mouseenter"
                          >
                            <StarRatings
                              rating={singleAd.matchRanking}
                              starDimension="17px"
                              starSpacing="0px"
                              numberOfStars={5}
                              starRatedColor="#B59410"
                              starEmptyColor="darkgrey"
                            />
                          </Tooltip>
                        </Box>
                      </div>

                      <a
                        href={singleAd.adUrl}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => handleJobAdLogoClick(singleAd)}
                        style={{ maxWidth: "4rem" }}
                      >
                        {singleAd.source === "Barona" ? (
                          <>
                            <Tooltip
                              theme="custom2"
                              size="small"
                              title={"Avaa alkuperäinen ilmoitus"}
                              position="left"
                              trigger="mouseenter"
                            >
                              <img
                                className="jobAdLogo"
                                src={BaronaLogo}
                              />
                            </Tooltip>
                            <img className="linkIcon" src={linkIcon} />
                          </>
                        ) : singleAd.source === "Arffman" ? (
                          <>
                            <Tooltip
                              theme="custom2"
                              size="small"
                              title={"Avaa alkuperäinen ilmoitus"}
                              position="left"
                              trigger="mouseenter"
                            >
                              <img
                                className="jobAdLogo"
                                src={ArffmanLogo}
                                style={{ backgroundColor: "white" }}
                              />
                            </Tooltip>
                            <img className="linkIcon" src={linkIcon} />
                          </>
                        ) : singleAd.source === "Työmarkkinatori" ? (
                          <>
                            <Tooltip
                              theme="custom2"
                              size="small"
                              title={"Avaa alkuperäinen ilmoitus"}
                              position="left"
                              trigger="mouseenter"
                            >
                              <img
                                className="jobAdLogo"
                                src={BaronaLogo}
                              />
                            </Tooltip>
                            <img className="linkIcon" src={linkIcon} />
                          </>
                        ) : (
                          <></>
                        )}
                      </a>
                    </div>
                    <Collapse in={showCollapse[i]}>
                      <div>
                        <div className="openAdDiv">
                          <div>
                            <h5 className="jobAdh5">
                              {t("rekry.tyopaikat.alueet")}
                            </h5>
                            <p className="jobAdParagraph">
                              {singleAd.areas
                                .toString()
                                .replaceAll(",", ", ")}
                            </p>
                            <h5 className="jobAdh5">
                              {t("rekry.tyopaikat.kaupungit")}
                            </h5>
                            <p className="jobAdParagraph">
                              {singleAd.cities
                                .toString()
                                .replaceAll(",", ", ")}
                            </p>
                          </div>
                          <div>
                            <h5 className="jobAdh5">
                              {t("rekry.tyopaikat.sopimus")}
                            </h5>
                            <p className="jobAdParagraph">
                              {singleAd.contractType.toString()}
                            </p>
                            <h5 className="jobAdh5">
                              {t("rekry.tyopaikat.luonne")}
                            </h5>
                            <p className="jobAdParagraph">
                              {singleAd.workType.toString()}
                            </p>
                          </div>
                          <div>
                            <h5 className="jobAdh5">
                              {t("rekry.tyopaikat.haku")}
                            </h5>
                            <p className="jobAdParagraph">
                              {new Date(
                                singleAd.endDate.toString()
                              ).toLocaleDateString("fi")}
                            </p>
                            <h5 className="jobAdh5">
                              {t("rekry.tyopaikat.yritys")}
                            </h5>
                            <p className="jobAdParagraph">
                              {singleAd.company.toString()}
                            </p>
                          </div>
                        </div>
                        <div className="jobDescDiv">
                          {singleAd.description}
                        </div>
                      </div>
                    </Collapse>
                  </li>
                </>
              ))}
            </ul>
          </>
        ) : (
          <>
            {!rankedPostings || rankedPostings.length === 0 ? (
              <>-</>
            ) : (
              <>
                <div className="jobAdsSpinner">
                  <CircularProgress />
                </div>
              </>
            )}
          </>
        )}
      </>
    )}
  </Paper>
  )
}

export default JobAds