import React from "react";
import { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import "../i18n";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../components/Misc/LoadingSpinner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Check, CheckLg } from "react-bootstrap-icons";

function AcceptRequest() {
    const [loading, setLoading] = useState(false);
    const [answer, setAnswer] = useState("");
    const [multi, setMulti] = useState(false);
    const [workSearchArr, setWorkSearchArr] = useState([]);
    const [acceptAll, setAcceptAll] = useState(false);
const [permissionLoading, setPermissionLoading] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {


        let userId = window.location.href.toString().split('type=')[1].split('=')[0];
        let reqAnswer = window.location.href.toString().split('&answer=')[1].split("&")[0];
        
        if(userId === "single") handleIdCheck();
        else if(userId === "multi"){
            let arr = window.location.href.toString().split('id=')[1].split(';');
            console.log('arr', arr);
            let displayArr = new Array()
            for(let i = 0; arr.length > i; i++){
                let str = arr[i].replaceAll("%C3%B6", 'ö').replaceAll("%20", " ").replaceAll("%C3%A4", 'ä').split("&answer")[0]
                console.log('string', str);
                displayArr.push({'name': str.split("%")[1], id: str.split("%")[0]})
            }
            console.log('displayArr', displayArr);
            setWorkSearchArr(displayArr)
            setMulti(true)
        }

        else if(userId === "acceptAll"){
            setAcceptAll(true)
            let arr = window.location.href.toString().split('id=')[1].split(';');
            console.log('arr', arr);
            let displayArr = new Array()
            for(let i = 0; arr.length > i; i++){
                let str = arr[i].replaceAll("%C3%B6", 'ö').replaceAll("%20", " ").replaceAll("%C3%A4", 'ä').split("&answer")[0]
                console.log('string', str);
                displayArr.push({'name': str.split("%")[1], id: str.split("%")[0], lupa: reqAnswer === "accept" ? true:false, selected:true})
            }

            handlePermissionSubmit(displayArr, reqAnswer === "accept" ? true : false)
        }
        //
        console.log("userId", userId, reqAnswer)
    }, []);

 
    console.log("answer", answer)
    const notifySuccess = () =>
    toast.success(" ", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifyError = () =>
    toast.error(
      "Virhe: Yritä uudelleen tai ota yhteyttä palveluasiantuntijaasi.",
      {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );

  const notifySelectFirst = () =>
    toast.error(
      "Please select yes/no on all options.",
      {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );

    const notifyDuplicate = () =>
    toast.error(
      "Olet jo vastannut tähän pyyntöön.",
      {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );

  const notifyNoPermitSet = () =>
    toast.error(
      "Et ole asettanut vielä tietojenkäsittelylupaasi. Paina ruudun alareunassa olevaa Asetukset nappulaa asettaaksesi tietojenkäsittelyluvan.",
      {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );

    const { t, i18n } = useTranslation();

    function handlePermissionSubmit(arr, answer) {
      
        let check = true
        if(multi){
            for (let i = 0; workSearchArr.length > i; i++) {
                if (workSearchArr[i].selected !== true) {
                  check = false;
                  break;
                }
              }
        }

        let obj 
        if(!multi) obj = arr
        else obj = workSearchArr
        if (check) {
          setLoading(true)
          const response = fetch("/api/workSearchPermissionSubmit/" + '12345', {
            method: "POST",
            body: JSON.stringify(obj),
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then(function (response) {
              return response.text();
            })
            .then(function (data) {
              if(data !== "Duplicate"){
                {answer ? setAnswer(true) : setAnswer(false)}
                if(multi)setAnswer(true)
              }
              if(data === "Duplicate") setAnswer("Duplicate")

              setLoading(false)
            })
            .catch((error) => {
              setLoading(false)
              console.log("error: " + error);
             notifyError()
            });
        }
    else notifySelectFirst()
        
      }

      async function handleIdCheck() {
        
        setLoading(true);
        let userId = window.location.href.toString().split('id=')[1].split('&')[0];
        let id = window.location.href.toString().split('id=')[1].split('%')[0]
        let reqAnswer = window.location.href.toString().split('&answer=')[1].split("&")[0];
        let lupa
        {reqAnswer === "accept" ? lupa = true: lupa = false}
        console.log("reqanswer", reqAnswer)
        //setAnswer(reqAnswer)
        let obj = [{ id:id, lupa:lupa }];

        const response = await fetch('/api/workSearchPermissionSubmit/12345', {
            method: 'POST',
            body: JSON.stringify(obj),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(function (response) {
                return response.text();
            })
            .then(function (data) {
              console.log("data", data)
              if(data !== "Duplicate"){
                {reqAnswer === "accept" ? setAnswer(true) : setAnswer(false)}
              }
              if(data === "Duplicate") setAnswer("Duplicate")

              setLoading(false)
            })
            .catch((error) => {
                console.log("error: ", error)
                setLoading(false)
            });         
    }

console.log("loading", loading, answer)
      function setPermissions(item, action, index) {
    
        const handleUpdate = (index, single, action) => {
          const newWorkSearchs = [...workSearchArr];
          newWorkSearchs[index] = {
            ...single,
            ['lupa']: action, ['selected']: true
          };
          setWorkSearchArr(newWorkSearchs);
        }
    
        if (action === "yes") {
          console.log("ajanko yes")
          handleUpdate(index, item, true)
        }
        if (action === "no") {
          console.log("ajanko no")
          handleUpdate(index, item, false)
        }
    
      }

      console.log('workSearchArr', workSearchArr);
      
    

    if(acceptAll || !multi){
        return(
            <div id="tableContainerRekry">
            <div id="tableView" style={{ marginBottom: '7dvh', display: 'flex', flexDirection: 'column' }}>
              {loading ? 
              <>
              <div>  <LoadingSpinner/></div>
            
              </>
              :
              <>
                {answer === "Duplicate" ? 
              <>
               <>
                                <p>
                                   Olet jo vastannut tähän esittelypyyntöön.
                                </p>
                            </>
              </>
              :
              <>
              {answer === true ?
                            <>
                                <p>
                                    Esittelypyyntö vahvistettu! Voit nyt sulkea tämän ikkunan.
                                </p>
                            </>
                            :
                            <>
                                <p>
                                    Esittelypyyntö hylätty! Voit nyt sulkea tämän ikkunan.
                                </p>
                            </>
                        }
              </>
              }
              </>
              }
            
            
              
            </div>
            </div>
        )
    }

    else if(multi){
        return(
            <div id="tableContainerRekry">
             <div className="percentH2DivAlt">
                <h1 className="omatTiedotHeaderAlt">{t("rekry.newWorkSearchNotification")}</h1>
              </div>
              <div id="tableView" style={{ marginBottom: '7dvh', display: 'flex', flexDirection: 'column' }}>
                <div>
                    {answer ?
                    <>
                    {answer === "Duplicate" ? 
                    <>
                     Olet jo vastannut tähän esittelypyyntöön.
                    </>
                    :
                    <>
                    Valinnat tallennettu!
                    <CheckLg size="40" color="green"/>
                    </>
                    }
                    
                    </>
                    :
                    <>
                    <Table>
                        <thead>
                          <th>{t("rekry.yrityksenNimi")}</th>
                          <th>{t("rekry.lupa")}</th>
                        </thead>
                        <tbody>
                          {workSearchArr.map((item, index) => (
                            <tr>
                              <td>
                                {item.name}
                              </td>
                              <td>
                                <label style={{ marginRight: '10px' }}>{t("dataconsent.select2")}</label>
                                <input name={"permission" + index} onClick={() => setPermissions(item, "yes", index)} style={{ marginRight: '10px' }} type="radio"></input>
                                <label style={{ marginRight: '10px' }} >{t("dataconsent.select3")}</label>
                                <input name={"permission" + index} onClick={() => setPermissions(item, "no", index)} type="radio"></input>
                              </td>
                            </tr>
                          ))}
                          <tr>
                            {loading ?
                              <>
                                <td colspan="2"><button onClick={handlePermissionSubmit} className="button-81"><LoadingSpinner type="verySmall" /></button></td>
                              </>
                              :
                              <>
                                <td colspan="2"><button onClick={handlePermissionSubmit} className="button-81">Tallenna</button></td>
                              </>
                              }
      
                          </tr>
                        </tbody>
      
                      </Table>
                    </>
                    }
             
                      
                
                      </div>
                    </div>
               </div>
        )
  
  
}
}

            export default AcceptRequest;
