import React, { useState, useEffect } from "react";
import { XLg } from "react-bootstrap-icons";

function Picklist({
  userData,
  field,
  editSingleSelect,
  editFunction,
  cancelEdit,
  picklist,
}) {
  const [pickListState, setpickListState] = useState(picklist[0]);
  useEffect(() => {
    if (sessionStorage.getItem("lang") === "fi") {
      setpickListState(picklist[0]);
    } else {
      setpickListState(picklist[1]);
    }
  });

  //console.log("picklistComponent", userData, "haloo", field)
  return (
    <>
      {userData && userData[field] ? (
        <>
          {userData && userData[field].checked ? (
            <div style={{ display: "flex", justifyContent: "right" }}>
              <select
                onChange={(e) =>
                  editSingleSelect(e.target.value, userData[field])
                }
                defaultValue={userData[field]?.value}
              >
                {pickListState.map((name, index) => (
                  <option value={picklist[0][index]} key={name}>
                    <p>{name}</p>
                  </option>
                ))}
              </select>
              <div className="cancelContainer">
                <button
                  className="cancelButton"
                  onClick={(e) => cancelEdit(userData[field])}
                >
                  <XLg size={"20"} />
                </button>
              </div>
            </div>
          ) : (
            <>
              {userData[field].value ? (
                <button
                  onClick={(e) => editFunction(userData[field])}
                  style={{ background: "none", borderRadius: "8px" }}
                >
                  {userData[field].value}
                </button>
              ) : (
                <button
                  onClick={(e) => editFunction(userData[field])}
                  style={{ background: "none", borderRadius: "8px" }}
                >
                  -
                </button>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <button
            onClick={(e) => editFunction(userData[field])}
            style={{ background: "none", borderRadius: "8px" }}
          >
            -
          </button>
        </>
      )}
    </>
  );
}

export default Picklist;
