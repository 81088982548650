import React, { useState, useEffect } from "react";
import Picklist from "../components/Picklist";

import LoadingSpinner from "../components/Misc/LoadingSpinner";
import {
  Ammattialat,
  Ammatit,
  Lupakortit_ja_osaamispassit,
  Ty_kokemusta_yhteens,
  Tyonhakualat,
  Hakualueet,
  Palkkatoive,
  Paikkakunnat,
  HaettavatTeht,
  Asema,
  Ty_nhaun_prioriteetti,
  Tyomatka,
  Rajoitteet,
  Ajokortti,
  Tyokokeilu,
  Palkkatuki,
  Vaihtoehdot,
  Auto,
  Ty_lupa_ja_pankkitili,
  Koulutusaste,
  Koulutusala,
} from "../picklists";
import Dropdown from "../components/Dropdown";
import Textbox from "../components/Textbox";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RadioButton from "../components/RadioButton";
import DataConsent from "../components/DataConsent";
import SettingsIcon from "@mui/icons-material/Settings";
import { useTranslation } from "react-i18next";
import "../i18n";
import { Grid, Typography, Button, CircularProgress } from "@mui/material";
import Container from "@mui/material/Container";
import { Paper, Box, IconButton } from "@mui/material";
import JobAds from "../components/jobAds/JobAds";

const notifySuccess = () =>
  toast.success("Tiedot tallennettu!", {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

const notifyError = () =>
  toast.error(
    "Virhe: Yritä uudelleen tai ota yhteyttä palveluasiantuntijaasi.",
    {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
  );

function HakuProfiili({
  rekryData,
  getRekryData,
  setRekryData,
  userData,
  getUserData,
  originalData
}) {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(null);
  const [modifiedData, setModifiedData] = useState(null);
  const [editCount, setEditCount] = useState(0);
  const [permission] = useState(sessionStorage.getItem("permission"));
  const { t } = useTranslation();
  const progressValueCalculator = () => {
    let counter = 0;
    let fieldCount = 0;
    for (const item in rekryData) {
      if (rekryData[item].value) {
        counter++;
      }
      fieldCount++;
    }
    return Math.round((counter / fieldCount) * 100);
  };
  const progressValue = progressValueCalculator();

  function handleShowModal() {
    setShow(true);
  }

  function handleCloseModal() {
    setShow(false);
  }

  async function handleSubmit(value) {
    setLoading(true);
    await fetch("/api/user/submitrekrydata/" + sessionStorage.getItem("id"), {
      method: "POST",
      body: JSON.stringify(rekryData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.toString() === "ok") {
          getRekryData("reFetch");
          setLoading(false);
          setModifiedData(false);
          notifySuccess();
          setEditCount(0);
        }
      })
      .catch((error) => {
        console.log("error: " + error);
        setLoading(false);
        notifyError();
        setEditCount(0);
      });
  }

  function editFunction(choice) {
    if (!loading) setModifiedData(true);
    setEditCount(editCount + 1);
    setRekryData((rekryData) => {
      return {
        ...rekryData,
        [choice.objName]: { ...choice, checked: !choice.checked },
      };
    });
  }

  function cancelEdit(choice) {
    setEditCount(editCount - 1);
    let originalValue = JSON.parse(originalData);
    originalValue = originalValue[choice.objName].value
    setRekryData((rekryData) => {
      return {
        ...rekryData,
        [choice.objName]: { ...choice, checked: false, value: originalValue },
      };
    });
    if (editCount === 1) setModifiedData(false);
  }

  function editValue(choice, e) {
    setRekryData((rekryData) => {
      return { ...rekryData, [choice.objName]: { ...choice, value: e } };
    });
  }

  function deleteFromList(e, choice) {
    let newList = rekryData[choice].value;
    rekryData[choice].value.forEach((element, index) => {
      if (element === e) {
        newList.splice(index, 1);
      }
      setRekryData((rekryData) => {
        return {
          ...rekryData,
          [choice.objName]: { ...choice, value: newList },
        };
      });
    });
  }

  function AddToList(e, choice) {
    let newList;
    if (rekryData[choice.objName].value) {
      newList = rekryData[choice.objName].value;
    } else {
      newList = [];
    }
    let check = false;
    if (newList.length > 0) {
      newList.forEach((element) => {
        if (element === e) {
          check = true;
        }
      });
    }

    if (!check) {
      newList.push(e);
      setRekryData((rekryData) => {
        return {
          ...rekryData,
          [choice.objName]: { ...choice, value: newList },
        };
      });
    }
  }

  function editSingleSelect(e, choice) {
    setRekryData((rekryData) => {
      return { ...rekryData, [choice.objName]: { ...choice, value: e } };
    });
  }

  useEffect(() => {
    if (!userData) getUserData();
    if (!rekryData) getRekryData();
  }, []);

  return (
    <>
      <Container id="tableContainerRekry">
        <Grid container sx={{ marginTop: "20px" }}>
          <Grid item lg={6} md={12} xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                placeContent: "space-between",
                height: "100%",
              }}
            >
              <Box>
                <Typography sx={{ marginBottom: "15px" }} variant="h3">
                  {t("rekry.tayttoaste")}
                </Typography>
                <Box
                  sx={{
                    position: "relative",
                    display: "inline-flex",
                    //marginBottom:'40px'
                  }}
                >
                  <CircularProgress
                    sx={{ width: "200px!important", height: "200px!important" }}
                    variant="determinate"
                    value={progressValue}
                  />
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="h3"
                      component="div"
                      color="text.secondary"
                    >
                      {`${Math.round(progressValue)}%`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ height: "100%" }}>
                <Paper sx={{ height: "100%" }}>
                  <table id="personalData2" className="content-table">
                    <thead></thead>
                    <tbody>
                      <DataConsent
                        show={show}
                        handleCloseModal={handleCloseModal}
                        notifyError={notifyError}
                        notifySuccess={notifySuccess}
                      />

                      <tr id="textType">
                        <td id="pointer">
                          <Typography variant="body1">
                            {t("rekry.hakualueet")}
                          </Typography>
                        </td>

                        <td className="editTd" id="Mobile">
                          <Dropdown
                            userData={rekryData}
                            field={"hakuAlueet"}
                            AddToList={AddToList}
                            deleteFromList={deleteFromList}
                            handleSubmit={handleSubmit}
                            editFunction={editFunction}
                            cancelEdit={cancelEdit}
                            picklist={Hakualueet}
                            permission={permission}
                          />
                        </td>
                      </tr>

                      <tr id="textType">
                        <td id="pointer">
                          <Typography variant="body1">
                            {t("rekry.paikkakunnat")}
                          </Typography>
                        </td>

                        <td className="editTd" id="Mailing_Street">
                          <Dropdown
                            userData={rekryData}
                            field={"paikkaKunnat"}
                            AddToList={AddToList}
                            deleteFromList={deleteFromList}
                            handleSubmit={handleSubmit}
                            editFunction={editFunction}
                            cancelEdit={cancelEdit}
                            picklist={Paikkakunnat}
                            permission={permission}
                          />
                        </td>
                      </tr>

                      <tr id="multi">
                        <td id="pointer">
                          <Typography variant="body1">
                            {t("rekry.ajokortti")}
                          </Typography>
                        </td>

                        <td className="editTd" id="Ammattialat">
                          <Dropdown
                            userData={rekryData}
                            field={"ajoKortti"}
                            AddToList={AddToList}
                            deleteFromList={deleteFromList}
                            handleSubmit={handleSubmit}
                            editFunction={editFunction}
                            cancelEdit={cancelEdit}
                            picklist={Ajokortti}
                            permission={permission}
                          />
                        </td>
                      </tr>

                      <tr id="multi">
                        <td id="pointer">
                          <Typography variant="body1">
                            {t("rekry.auto")}
                          </Typography>
                        </td>

                        <td className="editTd" id="Lupakortit_ja_osaamispassit">
                          <Picklist
                            userData={rekryData}
                            field={"onkoAuto"}
                            AddToList={AddToList}
                            deleteFromList={deleteFromList}
                            handleSubmit={handleSubmit}
                            editFunction={editFunction}
                            cancelEdit={cancelEdit}
                            editSingleSelect={editSingleSelect}
                            picklist={Auto}
                            permission={permission}
                          />
                        </td>
                      </tr>

                      <tr id="textType">
                        <td id="pointer">
                          <Typography variant="body1">
                            {t("rekry.haettavatTeht")}
                          </Typography>
                        </td>

                        <td className="editTd" id="Mailing_Zip">
                          <Dropdown
                            userData={rekryData}
                            field={"haettavatTeht"}
                            AddToList={AddToList}
                            deleteFromList={deleteFromList}
                            handleSubmit={handleSubmit}
                            editFunction={editFunction}
                            cancelEdit={cancelEdit}
                            picklist={HaettavatTeht}
                            permission={permission}
                          />
                        </td>
                      </tr>

                      <tr id="textType">
                        <td id="pointer">
                          <Typography variant="body1">
                            {t("rekry.asema")}
                          </Typography>
                        </td>

                        <td className="editTd" id="Mailing_City">
                          <Picklist
                            userData={rekryData}
                            field={"asema"}
                            AddToList={AddToList}
                            deleteFromList={deleteFromList}
                            handleSubmit={handleSubmit}
                            editFunction={editFunction}
                            cancelEdit={cancelEdit}
                            editSingleSelect={editSingleSelect}
                            picklist={Asema}
                            permission={permission}
                          />
                        </td>
                      </tr>

                      <tr id="textType">
                        <td id="pointer">
                          <Typography variant="body1">
                            {t("rekry.hakualat")}
                          </Typography>
                        </td>

                        <td className="editTd" id="idinkieli">
                          <Dropdown
                            userData={rekryData}
                            field={"hakuAlat"}
                            AddToList={AddToList}
                            deleteFromList={deleteFromList}
                            handleSubmit={handleSubmit}
                            editFunction={editFunction}
                            cancelEdit={cancelEdit}
                            picklist={Tyonhakualat}
                            permission={permission}
                          />
                        </td>
                      </tr>

                      <tr id="textType">
                        <td id="pointer">
                          <Typography variant="body1">
                            {t("rekry.tyomatka")}
                          </Typography>
                        </td>

                        <td className="editTd" id="Muu_kielitaito">
                          <Picklist
                            userData={rekryData}
                            field={"liikkuminen"}
                            AddToList={AddToList}
                            deleteFromList={deleteFromList}
                            handleSubmit={handleSubmit}
                            editFunction={editFunction}
                            cancelEdit={cancelEdit}
                            editSingleSelect={editSingleSelect}
                            picklist={Tyomatka}
                            permission={permission}
                          />
                        </td>
                      </tr>

                      <tr id="textType">
                        <td id="pointer">
                          <Typography variant="body1">
                            {t("rekry.prioriteetti")}
                          </Typography>
                        </td>
                        <td className="editTd" id="Koulutus">
                          <Picklist
                            userData={rekryData}
                            field={"prioriteetti"}
                            AddToList={AddToList}
                            deleteFromList={deleteFromList}
                            handleSubmit={handleSubmit}
                            editFunction={editFunction}
                            cancelEdit={cancelEdit}
                            editSingleSelect={editSingleSelect}
                            picklist={Ty_nhaun_prioriteetti}
                            permission={permission}
                          />
                        </td>
                      </tr>

                      <tr id="single">
                        <td id="pointer">
                          <Typography variant="body1">
                            {t("rekry.palkkaToive")}
                          </Typography>
                        </td>
                        <td className="editTd" id="Koulutusaste">
                          <Picklist
                            userData={rekryData}
                            field={"palkkaToive"}
                            AddToList={AddToList}
                            deleteFromList={deleteFromList}
                            handleSubmit={handleSubmit}
                            editFunction={editFunction}
                            cancelEdit={cancelEdit}
                            editSingleSelect={editSingleSelect}
                            picklist={Palkkatoive}
                            permission={permission}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Paper>
              </Box>
            </Box>
          </Grid>

          <Grid item lg={6} md={12} xs={12}>
            <Paper style={{ height: "100%" }}>
              <table id="personalData2" className="content-table">
                <thead></thead>
                <tbody>
                  <tr id="textType">
                    <td id="pointer">
                      <Typography variant="body1">
                        {t("rekry.haetkoToita")}
                      </Typography>
                    </td>
                    <td className="editTd" id="Last_Name">
                      <RadioButton
                        userData={rekryData}
                        field={"tyonHakija"}
                        handleSubmit={handleSubmit}
                        editFunction={editFunction}
                        cancelEdit={cancelEdit}
                        editSingleSelect={editSingleSelect}
                        permission={permission}
                      />
                    </td>
                  </tr>

                  <tr id="multi">
                    <td id="pointer">
                      <Typography variant="body1">
                        {t("rekry.rajoitteet")}
                      </Typography>
                    </td>
                    <td className="editTd" id="Koulutusala">
                      <Dropdown
                        userData={rekryData}
                        field={"rajoitteet"}
                        AddToList={AddToList}
                        deleteFromList={deleteFromList}
                        handleSubmit={handleSubmit}
                        editFunction={editFunction}
                        cancelEdit={cancelEdit}
                        picklist={Rajoitteet}
                        permission={permission}
                      />
                    </td>
                  </tr>
                  <tr id="textType">
                    <td id="pointer">
                      <Typography variant="body1">
                        {t("rekry.kuvaus")}
                      </Typography>
                    </td>
                    <td className="editTd" id="Email">
                      <Textbox
                        userData={rekryData}
                        field={"omaKuvaus"}
                        handleSubmit={handleSubmit}
                        editFunction={editFunction}
                        cancelEdit={cancelEdit}
                        editValue={editValue}
                        type={"textArea"}
                        permission={permission}
                      />
                    </td>
                  </tr>

                  <tr id="textType">
                    <td id="pointer">
                      <Typography variant="body1">
                        {t("rekry.koulutus")}
                      </Typography>
                    </td>
                    <td className="editTd" id="Koulutus">
                      <Textbox
                        userData={rekryData}
                        field={"education"}
                        handleSubmit={handleSubmit}
                        editFunction={editFunction}
                        cancelEdit={cancelEdit}
                        editValue={editValue}
                        permission={permission}
                      />
                    </td>
                  </tr>

                  <tr id="multi">
                    <td id="pointer">
                      <Typography variant="body1">
                        {t("rekry.koulutusala")}
                      </Typography>
                    </td>
                    <td className="editTd" id="Koulutusala">
                      <Dropdown
                        userData={rekryData}
                        field={"eduField"}
                        AddToList={AddToList}
                        deleteFromList={deleteFromList}
                        handleSubmit={handleSubmit}
                        editFunction={editFunction}
                        cancelEdit={cancelEdit}
                        picklist={Koulutusala}
                        permission={permission}
                      />
                    </td>
                  </tr>

                  <tr id="single">
                    <td id="pointer">
                      <Typography variant="body1">
                        {t("rekry.koulutusAste")}
                      </Typography>
                    </td>

                    <td className="editTd" id="Koulutusaste">
                      <Picklist
                        userData={rekryData}
                        field={"eduLevel"}
                        AddToList={AddToList}
                        deleteFromList={deleteFromList}
                        handleSubmit={handleSubmit}
                        editFunction={editFunction}
                        cancelEdit={cancelEdit}
                        editSingleSelect={editSingleSelect}
                        picklist={Koulutusaste}
                        permission={permission}
                      />
                    </td>
                  </tr>

                  <tr id="multi">
                    <td id="pointer">
                      <Typography variant="body1">
                        {t("rekry.ammattialat")}
                      </Typography>
                    </td>
                    <td className="editTd" id="Lupakortit_ja_osaamispassit">
                      <Dropdown
                        userData={rekryData}
                        field={"fields"}
                        AddToList={AddToList}
                        deleteFromList={deleteFromList}
                        handleSubmit={handleSubmit}
                        editFunction={editFunction}
                        cancelEdit={cancelEdit}
                        picklist={Ammattialat}
                        permission={permission}
                      />
                    </td>
                  </tr>

                  <tr id="multi">
                    <td id="pointer">
                      <Typography variant="body1">
                        {t("rekry.ammatit")}
                      </Typography>
                    </td>

                    <td className="editTd" id="Ammatit">
                      <Dropdown
                        userData={rekryData}
                        field={"professions"}
                        AddToList={AddToList}
                        deleteFromList={deleteFromList}
                        handleSubmit={handleSubmit}
                        editFunction={editFunction}
                        cancelEdit={cancelEdit}
                        picklist={Ammatit}
                        permission={permission}
                      />
                    </td>
                  </tr>

                  <tr id="multi">
                    <td id="pointer">
                      <Typography variant="body1">
                        {t("rekry.tyokokeilu")}
                      </Typography>
                    </td>

                    <td className="editTd" id="Ammatit">
                      <Picklist
                        userData={rekryData}
                        field={"mahdTyokokeilu"}
                        AddToList={AddToList}
                        deleteFromList={deleteFromList}
                        handleSubmit={handleSubmit}
                        editFunction={editFunction}
                        cancelEdit={cancelEdit}
                        editSingleSelect={editSingleSelect}
                        picklist={Tyokokeilu}
                        permission={permission}
                      />
                    </td>
                  </tr>

                  <tr id="single">
                    <td id="pointer">
                      <Typography variant="body1">
                        {t("rekry.palkkaTuki")}
                      </Typography>
                    </td>

                    <td className="editTd" id="Ty_kokemusta_yhteens">
                      <Picklist
                        userData={rekryData}
                        field={"mahdPalkkatuki"}
                        AddToList={AddToList}
                        deleteFromList={deleteFromList}
                        handleSubmit={handleSubmit}
                        editFunction={editFunction}
                        cancelEdit={cancelEdit}
                        editSingleSelect={editSingleSelect}
                        picklist={Palkkatuki}
                        permission={permission}
                      />
                    </td>
                  </tr>

                  <tr id="single">
                    <td id="pointer">
                      <Typography variant="body1">
                        {t("rekry.vaihtoehdot")}
                      </Typography>
                    </td>

                    <td className="editTd" id="Ty_kokemusta_yhteens">
                      <Dropdown
                        userData={rekryData}
                        field={"vaihtoEhdot"}
                        AddToList={AddToList}
                        deleteFromList={deleteFromList}
                        handleSubmit={handleSubmit}
                        editFunction={editFunction}
                        cancelEdit={cancelEdit}
                        picklist={Vaihtoehdot}
                        permission={permission}
                      />
                    </td>
                  </tr>

                  <tr id="single">
                    <td id="pointer">
                      <Typography variant="body1">
                        {t("rekry.tyolupa")}
                      </Typography>
                    </td>

                    <td className="editTd" id="Ty_lupa_ja_pankkitili">
                      <Dropdown
                        userData={rekryData}
                        field={"tyoLupa"}
                        AddToList={AddToList}
                        deleteFromList={deleteFromList}
                        handleSubmit={handleSubmit}
                        editFunction={editFunction}
                        cancelEdit={cancelEdit}
                        picklist={Ty_lupa_ja_pankkitili}
                        permission={permission}
                      />
                    </td>
                  </tr>

                  <tr id="single">
                    <td id="pointer">
                      <Typography variant="body1">
                        {t("rekry.tyokokemus")}
                      </Typography>
                    </td>

                    <td className="editTd" id="Ty_kokemusta_yhteens">
                      <Picklist
                        userData={rekryData}
                        field={"experience"}
                        AddToList={AddToList}
                        deleteFromList={deleteFromList}
                        handleSubmit={handleSubmit}
                        editFunction={editFunction}
                        cancelEdit={cancelEdit}
                        editSingleSelect={editSingleSelect}
                        picklist={Ty_kokemusta_yhteens}
                        permission={permission}
                      />
                    </td>
                  </tr>

                  <tr id="multi">
                    <td id="pointer">
                      <Typography variant="body1">
                        {t("rekry.lupakortit")}
                      </Typography>
                    </td>

                    <td className="editTd" id="Lupakortit_ja_osaamispassit">
                      <Dropdown
                        userData={rekryData}
                        field={"passes"}
                        AddToList={AddToList}
                        deleteFromList={deleteFromList}
                        handleSubmit={handleSubmit}
                        editFunction={editFunction}
                        cancelEdit={cancelEdit}
                        picklist={Lupakortit_ja_osaamispassit}
                        permission={permission}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </Paper>
          </Grid>
        </Grid>

        <div
          className="save-btn-div"
          style={{ paddingBottom: "20px", width: "auto" }}
        >
          <div></div>
          <div className="middle">
            {loading ? (
              <LoadingSpinner type={"small"} />
            ) : (
              <>
                {modifiedData && (
                  <Button
                    className="save-button"
                    variant="contained"
                    style={{
                      background: "#4CAF50",
                      marginRight: "-40px",
                      marginTop: "20px",
                    }}
                    onClick={handleSubmit}
                  >
                    <Typography
                      sx={{ fontWeight: "bold", color: "#fefefe" }}
                      variant="body2"
                    >
                      {t("save")}
                    </Typography>
                  </Button>
                )}
              </>
            )}
          </div>
          <IconButton onClick={handleShowModal}>
            <SettingsIcon />
          </IconButton>
        </div>

        <JobAds userData={userData} rekryData={rekryData} />
        <br></br>
      </Container>
    </>
  );
}

export default HakuProfiili;

/*

{(approvedWorkSearchs && approvedWorkSearchs.length) ||
        (notApprovedWorkSearchs && notApprovedWorkSearchs.length) ? (
          <>
            <div className="percentH2DivAlt">
              <h1 className="omatTiedotHeaderAlt">
                {t("rekry.newWorkSearchNotification")}
              </h1>
            </div>
            <div
              id="tableView"
              style={{
                marginBottom: "7dvh",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div>
                {notApprovedWorkSearchs.length ? (
                  <>
                    <Table>
                      <thead>
                        <th>{t("rekry.yrityksenNimi")}</th>
                        <th>{t("rekry.lupa")}</th>
                      </thead>
                      <tbody>
                        {notApprovedWorkSearchs.map((item, index) => (
                          <tr>
                            <td>{item.yritys}</td>
                            <td>
                              <label style={{ marginRight: "10px" }}>
                                {t("dataconsent.select2")}
                              </label>
                              <input
                                name={"permission" + index}
                                onClick={() =>
                                  setPermissions(item, "yes", index)
                                }
                                style={{ marginRight: "10px" }}
                                type="radio"
                              ></input>
                              <label style={{ marginRight: "10px" }}>
                                {t("dataconsent.select3")}
                              </label>
                              <input
                                name={"permission" + index}
                                onClick={() =>
                                  setPermissions(item, "no", index)
                                }
                                type="radio"
                              ></input>
                            </td>
                          </tr>
                        ))}
                        <tr>
                          {permissionLoading ? (
                            <>
                              <td colspan="2">
                                <button
                                  onClick={handlePermissionSubmit}
                                  className="button-81"
                                >
                                  <LoadingSpinner type="verySmall" />
                                </button>
                              </td>
                            </>
                          ) : (
                            <>
                              <td colspan="2">
                                <button
                                  onClick={handlePermissionSubmit}
                                  className="button-81"
                                >
                                  Tallenna
                                </button>
                              </td>
                            </>
                          )}
                        </tr>
                      </tbody>
                    </Table>
                  </>
                ) : (
                  <>
                    <p style={{ textAlign: "left" }}>Ei uusia pyyntöjä</p>
                  </>
                )}
              </div>

              {approvedWorkSearchs.length ? (
                <>
                  <div style={{ float: "left" }}>
                    {workSearchCollapse ? (
                      <>
                        <h6
                          style={{ cursor: "pointer", textAlign: "left" }}
                          onClick={() =>
                            setWorkSearchCollapse(!workSearchCollapse)
                          }
                        >
                          Vastatut <CaretDownFill />
                        </h6>
                      </>
                    ) : (
                      <>
                        <h6
                          style={{ cursor: "pointer", textAlign: "left" }}
                          onClick={() =>
                            setWorkSearchCollapse(!workSearchCollapse)
                          }
                        >
                          Vastatut <CaretRightFill />
                        </h6>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <></>
              )}

              <div>
                <Collapse in={workSearchCollapse}>
                  <div>
                    <Table>
                      <thead>
                        <th>{t("rekry.yrityksenNimi")}</th>
                        <th>{t("rekry.lupa")}</th>
                      </thead>
                      <tbody>
                        {approvedWorkSearchs.map((item) => (
                          <tr>
                            <td>{item.yritys}</td>
                            <td>{item.lupa ? <>Kyllä</> : <>Ei</>}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Collapse>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

      
         
        </div>

        <div className="save-btn-div">
      <div></div> 
      <div className="middle">
        {loading ? (
          <LoadingSpinner type={"small"} />
        ) : (
          <>
            {modifiedData && (
              <Button
                className="save-button"
                variant="contained"
                style={{ background: "#4CAF50",marginRight:"-40px" }}
                onClick={handleSubmit}
              >
                <Typography sx={{ fontWeight: "bold" }} variant="body2">
                  {t("save")}
                </Typography>
              </Button>
            )}
          </>
        )}
      </div>
      <IconButton onClick={handleShowModal}>
        <SettingsIcon />
      </IconButton>
    </div>

   
*/
