import React, {
  useState,
  useEffect,
  useCallback,
  createContext,
  useRef,
} from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../components/Misc/LoadingSpinner";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import "../i18n";
import { Typography, Paper } from "@mui/material";

function Palaute() {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const notifySuccess = () =>
    toast.success("Palaute lähetetty. Kiitos palautteestasi!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifyError = () =>
    toast.error(
      "Virhe: Palautetta ei voitu lähettää. Yritä uudelleen, tai ota yhteyttä palveluasiantuntijaasi.",
      {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );

  function handleSubmit() {
    setLoading(true);
    let feedBack = document.getElementById("feedBackText").value;
    let fBtype = document.getElementById("feedBackType").value;
    let obj = { feedBack, fBtype };
    // console.log(obj);

    const response = fetch(
      "/api/misc/feedback/" + sessionStorage.getItem("id"),
      {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then(function (response) {
        if (response.ok) return response;
        else {
          notifyError();
        }
      })
      .then(function (data) {
        document.getElementById("feedBackText").value = "";
        setLoading(false);
        notifySuccess();
      })
      .catch((error) => {
        console.log("error: " + error);
        setLoading(false);
      });
  }

  return (
    <>
      <div id="tableContainer">
        <Paper
          elevation={4}
          className="feedbackContainer"
          style={{ borderRadius: "8px" }}
        >
          <div className="percentH2Div" style={{ marginBottom: "4vh" }}>
            <Typography sx={{ marginBottom: "15px" }} variant="h3">
              {t("palaute.title")}
            </Typography>
          </div>

          <form id="feedBackForm">
            <label for="type">{t("palaute.type")}</label>
            <select id="feedBackType" name="type">
              <option value="Bugi">{t("palaute.bug")}</option>
              <option value="Parannusehdotus">{t("palaute.tip")}</option>
              <option value="Idea">{t("palaute.idea")}</option>
              <option value="Muu">{t("palaute.other")}</option>
            </select>

            <label for="feedBack">{t("palaute.textTitle")}:</label>
            <textarea
              id="feedBackText"
              name="feedBack"
              placeholder={t("palaute.text")}
              style={{ height: "200px" }}
            ></textarea>
          </form>
          {loading ? (
            <button id="feedBackSubmit" onClick={handleSubmit}>
              <LoadingSpinner type={"small"} />
            </button>
          ) : (
            <button id="feedBackSubmit" onClick={handleSubmit}>
              {t("palaute.send")}
            </button>
          )}

          <hr />
          <p>{t("palaute.p1")}</p>
        </Paper>
      </div>
    </>
  );
}

export default Palaute;
