import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SendButton from "./SendButton";
import { Table, Form } from "react-bootstrap";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import "../../i18n";

function ContractCheck({ contract, setNextPage, pickedFile }) {
  const [form, setForm] = useState({
    companyId: null,
    name: null,
    workSite: null,
    street: null,
    zip: null,
    city: null,
    contactPersonFirstName: null,
    contactPersonLastName: null,
    contactPersonEmail: null,
    contactPersonPhone: null,
    contractType: null,
    sector: null,
    empType: null,
    startDate: null,
    endDate: null,
  });
  const [companyId, setCompanyId] = useState();
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  let newErrors = {};

  console.log("form: ", form);

  const handleFormEdit = (value, field) => {
    setForm((form) => {
      return { ...form, [field]: value };
    });
  };

  const handleFormEditUseEffect = (value, field) => {
    setForm((form) => {
      return { ...form, [field]: value };
    });
  };

  console.log("Contract", contract);
  useEffect(() => {
    for (const key in contract) {
      if (key === "zip") handleFormEditUseEffect(contract[key], "zip");
      if (key === "street") handleFormEditUseEffect(contract[key], "street");
      if (key === "employer")
        handleFormEditUseEffect(contract[key].fieldValue, "name");
      if (key === "city") handleFormEditUseEffect(contract[key], "city");

      //console.log(`${key}: ${contract[key]}`);
    }
  }, [contract]);

  const notifyAddSuccess = () =>
    toast.success("Ilmoitus onnistui!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifyFail = () =>
    toast.info("Jotain meni vikaan", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const HandleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setLoading(true);

    // Conditional logic:
    let file;
    let userData = JSON.parse(sessionStorage.getItem("userData"));
    let email = userData.instructor.value.email;
    if (pickedFile === undefined) file = false;
    else file = true;
    let user = JSON.parse(sessionStorage.getItem("userData"));
    let obj = { form, user, file, email };
    const response = await fetch(
      "/api/createContract/" + sessionStorage.getItem("id") + "/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      }
    )
      .then(function (response) {
        if (!response.ok) throw new Error(JSON.parse(response.body));
        return response.text();
      })
      .then(function (data) {
        if (pickedFile) uploadFiles(data);
        else {
          notifyAddSuccess();
          setNextPage(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error: " + error);
        setLoading(false);
        notifyFail();
      });
  };

  const uploadFiles = (id) => {
    let userData = JSON.parse(sessionStorage.getItem("userData"));
    let email = userData.instructor.value.email;
    let user =
      userData.recordFirstName.value + " " + userData.recordLastName.value;
    let projectName = userData.projectName.value;
    let projectId = userData.projectId.value;
    let fullName =
      userData.recordFirstName.value + userData.recordLastName.value;
    let language = "FI";
    let type = "Muu";

    let infoObj = {
      projectName,
      projectId,
      fullName,
      language,
      type,
      email,
      form,
      user,
    };

    const formData = new FormData();
    formData.append("fileInfo", JSON.stringify(infoObj));
    formData.append("file", pickedFile);

    const response = fetch(
      "/api/files/contractparser" + sessionStorage.getItem("id"),
      {
        method: "POST",
        body: formData,
        headers: {},
      }
    )
      .then(function (response) {
        return response.text();
      })
      .then(function (data) {
        updateDriveLink(id, data);
      })
      .catch((error) => {
        console.log("error: " + error);
        setLoading(false);
        notifyFail();
      });
  };

  const updateDriveLink = (orgId, driveId) => {
    let userData = JSON.parse(sessionStorage.getItem("userData"));
    let email = userData.instructor.value.email;
    let user = JSON.parse(sessionStorage.getItem("userData"));
    let obj = { orgId, driveId, email, user, form };
    const response = fetch(
      "/api/updateDriveLink/" + sessionStorage.getItem("id"),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      }
    )
      .then(function (response) {
        return response;
      })
      .then(function (data) {
        notifyAddSuccess();
        setNextPage(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error: " + error);
        setLoading(false);
        notifyFail();
      });
  };

  // <button onClick={() => updateDriveLink('41134000037682072', '1SXMhGOSdmUht-hlnROLVlWE9AOiKSMXI')}></button>

  return (
    <>
      <Form onSubmit={HandleSubmit}>
        <Table responsive>
          <tbody>
            <tr>
              <td>{t("contract.companyId")}</td>
              <Form.Group>
                <Form.Control
                  required
                  type="text"
                  onChange={(e) => handleFormEdit(e.target.value, "companyId")}
                  value={form.companyId}
                />
              </Form.Group>
            </tr>

            <tr>
              <td>{t("contract.employer")}</td>
              <Form.Group>
                <Form.Control
                  required
                  type="text"
                  onChange={(e) => handleFormEdit(e.target.value, "name")}
                  value={form.name}
                />
              </Form.Group>
            </tr>

            <tr>
              <td>{t("contract.workSite")}</td>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Työpaikan toimipiste"
                  onChange={(e) => handleFormEdit(e.target.value, "workSite")}
                  value={form.workSite}
                />
              </Form.Group>
            </tr>

            <tr>
              <td>{t("contract.street")}</td>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Työpaikan katuosoite"
                  onChange={(e) => handleFormEdit(e.target.value, "street")}
                  value={form.street}
                />
              </Form.Group>
            </tr>

            <tr>
              <td>{t("contract.zip")}</td>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Työpaikan postinumero"
                  onChange={(e) => handleFormEdit(e.target.value, "zip")}
                  value={form.zip}
                />
              </Form.Group>
            </tr>

            <tr>
              <td>{t("contract.city")}</td>
              <Form.Group>
                <Form.Control
                  required
                  type="text"
                  placeholder="Työpaikan kaupunki"
                  onChange={(e) => handleFormEdit(e.target.value, "city")}
                  value={form.city}
                />
              </Form.Group>
            </tr>

            <tr>
              <td>{t("contract.contactPersonFirstName")}</td>
              <Form.Group>
                <Form.Control
                  required
                  type="text"
                  placeholder="Yhteyshenkilön etunimi"
                  onChange={(e) =>
                    handleFormEdit(e.target.value, "contactPersonFirstName")
                  }
                  value={form.contactPersonFirstName}
                />
              </Form.Group>
            </tr>

            <tr>
              <td>{t("contract.contactPersonLastName")}</td>
              <Form.Group>
                <Form.Control
                  required
                  type="text"
                  placeholder="Yhteyshenkilön sukunimi"
                  onChange={(e) =>
                    handleFormEdit(e.target.value, "contactPersonLastName")
                  }
                  value={form.contactPersonLastName}
                />
              </Form.Group>
            </tr>

            <tr>
              <td>{t("contract.contactPersonEmail")}</td>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Yhteyshenkilön sähköposti"
                  onChange={(e) =>
                    handleFormEdit(e.target.value, "contactPersonEmail")
                  }
                  value={form.contactPersonEmail}
                />
              </Form.Group>
            </tr>

            <tr>
              <td>{t("contract.contactPersonPhone")}</td>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Yhteyshenkilön puhelinnumero"
                  onChange={(e) =>
                    handleFormEdit(e.target.value, "contactPersonPhone")
                  }
                  value={form.contactPersonPhone}
                />
              </Form.Group>
            </tr>

            <tr>
              <td>{t("contract.contractType")}</td>
              <td>
                <Form.Select
                  as="select"
                  id="select"
                  onChange={(e) =>
                    handleFormEdit(e.target.value, "contractType")
                  }
                  required
                  value={form.contractType}
                >
                  <option value="" selected disabled>
                    Valitse tyyppi
                  </option>
                  <option value="Määräaikainen">Määräaikainen</option>
                  <option value="Toistaiseksi voimassa oleva">
                    Toistaiseksi voimassa oleva
                  </option>
                </Form.Select>
              </td>
            </tr>

            <tr>
              <td>{t("contract.sector")}</td>
              <td>
                <Form.Group>
                  <Form.Select
                    required
                    as="select"
                    id="select"
                    onChange={(e) => handleFormEdit(e.target.value, "sector")}
                    value={form.sector}
                  >
                    <option value={form.sector} selected disabled>
                      {form.sector}
                    </option>
                    <option value="Yksityinen">Yksityinen</option>
                    <option value="Julkinen">Julkinen</option>
                    <option value="Kolmas">Kolmas</option>
                  </Form.Select>
                </Form.Group>
              </td>
            </tr>

            <tr>
              <td>{t("contract.employmentType")}</td>
              <td>
                <Form.Group>
                  <Form.Select
                    as="select"
                    id="select"
                    type="text"
                    placeholder="Valitse tyyppi"
                    value={form.empType}
                    onChange={(e) => handleFormEdit(e.target.value, "empType")}
                  >
                    <option value="" selected disabled>
                      Valitse tyyppi
                    </option>
                    <option value="Työssäoppiminen">Työssäoppiminen</option>
                    <option value="Työpaikka">Työpaikka</option>
                    <option value="Kieliharjoittelu">Kieliharjoittelu</option>
                    <option value="Koulutuskokeilu">Koulutuskokeilu</option>
                    <option value="Työkokeilu">Työkokeilu</option>
                    <option value="Työhön tutustuminen">
                      Työhön tutustuminen
                    </option>
                    <option value="Työvoimakoulutus">Työvoimakoulutus</option>
                  </Form.Select>
                </Form.Group>
              </td>
            </tr>

            <tr>
              <td>{t("contract.startDate")}</td>
              <Form.Group>
                <Form.Control
                  type="date"
                  value={form.startDate}
                  onChange={(e) => handleFormEdit(e.target.value, "startDate")}
                />
              </Form.Group>
            </tr>

            <tr>
              <td>{t("contract.endDate")}</td>
              <Form.Group>
                <Form.Control
                  type="date"
                  value={form.endDate}
                  onChange={(e) => handleFormEdit(e.target.value, "endDate")}
                />
              </Form.Group>
            </tr>
          </tbody>
        </Table>
        <SendButton loading={loading} />
      </Form>
    </>
  );
}

export default ContractCheck;
