import { React, useEffect, useState } from "react";
import Navigation from "./components/Navigation";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import WelcomePage from "./pages/WelcomePage";
import NoUser from "./images/user.png";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
  CircularProgress,
} from "@mui/material";
import { Modal, Button } from "react-bootstrap";
import OmatTiedot from "./pages/OmatTiedot";
import Home from "./pages/Home";
import { PrivateRoute } from "./PrivateRoute";
import HakuProfiili from "./pages/HakuProfiili";
import Palaute from "./pages/Palaute";
import Tiedostot from "./pages/Tiedostot";
import Forms from "./pages/Forms";
import WhatsNew from "./pages/WhatsNew";
import { useTranslation } from "react-i18next";
import "./i18n";
import ContractPage from "./pages/ContractPage";
import AcceptRequest from "./pages/AcceptRequest";
import Footer from "./components/Footer";
import { ToastContainer, toast } from "react-toastify";
import DataConsentForm from "./components/Consents/DataConsentForm";

const notifyError = (msg) =>
  toast.error(msg, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

const App = () => {
  const [refreshNav, setRefreshNav] = useState(true);
  const [login, setLogin] = useState(false);
  const [insModal, setInsModal] = useState(false);
  const [userPhoto, setUserPhoto] = useState(null);
  const [showConsentForm, setShowConsentForm] = useState(false);
  const [loading, setLoading] = useState();
  const [userConsents, setUserConsents] = useState({
    palveluntiedot: { id: null, date: null, checked: true },
    tyonhakuprofiili: { id: null, date: null, checked: false },
    tyosuhdetiedot: { id: null, date: null, checked: false },
    henkilotiedot: { id: null, date: new Date().toLocaleDateString("FI-fi"), checked: true },
  });
  const [userData, setUserData] = useState();
  const [rekryData, setRekryData] = useState();
  const [projectLinks, setProjectLinks] = useState([]);
  const [webinars, setWebinars] = useState(null);
  const { t, i18n } = useTranslation();
  const [handleFirstNavigation, setHandleFirstNavigation] = useState(false);
  const [originalData, setOriginalData] = useState(rekryData);
  const changeLanguage = (lng) => {
    sessionStorage.setItem("lang", lng);
    i18n.changeLanguage(lng);
  };

  let theme = createTheme({
    palette: {
      green: {
        main: "#4CAF50",
      },
      red: {
        main: "#EE4B2B",
      },
      primary: {
        main: "#f08262",
      },
      secondary: {
        main: "#7db5da",
      },
    },
  });
  theme = responsiveFontSizes(theme);

  const handleUpdatingConsents = (consents) => {
    localStorage.setItem("consents", JSON.stringify(consents || userConsents));
  };

  useEffect(() => {
    setRefreshNav(!refreshNav);
  }, [login, userConsents]);

  useEffect(() => {
    if (sessionStorage.getItem("lang")) {
      changeLanguage(sessionStorage.getItem("lang"));
    } else {
      changeLanguage("fi");
    }

    const storedConsents = localStorage.getItem("consents");
    if (storedConsents) setUserConsents(JSON.parse(storedConsents));
  }, []);

  useEffect(() => {
    if (!webinars) getWebinars();
  }, []);

  function handleLogin() {
    setLogin(true);
    sessionStorage.setItem("goASifkFD4235", true);
  }

  async function handleLogOut() {
    fetch("/api/user/logout", {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    }).then(function (response) {});
    setLogin(false);
    sessionStorage.clear();
    localStorage.clear();
  }

  useEffect(() => {
    if (sessionStorage.getItem("goASifkFD4235")) setLogin(true);
  }, []);

  const getConsent = () => {
    if(sessionStorage.getItem("consentNeeded") === "false"){
      console.log('täähän se ois: ', sessionStorage.getItem("consentNeeded"));
      handleLogin()
      setHandleFirstNavigation("navigate")
    }
    else{
    const userConsentAlreadyGiven = sessionStorage.getItem("dataConsent"); //gets information from login
    if (!userConsentAlreadyGiven) setShowConsentForm(true); // if consent not given show form 
    else getSpecificConsents() //gets specific consents from module if consent given
  }
  };

  const getSpecificConsents = () => {
    const projectId = sessionStorage.getItem("project");
    let consentObject = userConsents;
    fetch("/api/consents/getConsent/" + sessionStorage.getItem("id"), {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ projectId }),
    })
      .then((response) => response.json())
      .then(function (data) {
        if (data) {
          if (data?.consentRequirement === "not required") {
            consentObject.notRequired = true
          }

          if (data?.consentsFound === true && data?.consents?.length > 0) {
            for (let i = 0; i < data.consents.length; i++) {
              const row = data.consents[i];
              if (row.consentType === "tyosuhdetiedot") {
                consentObject.tyosuhdetiedot.id = row.consentId;
                consentObject.tyosuhdetiedot.date = row.consentDate;
                consentObject.tyosuhdetiedot.checked = row.consentId
                  ? true
                  : false;
              }
              if (row.consentType === "henkilotiedot") {
                consentObject.henkilotiedot.id = row.consentId;
                consentObject.henkilotiedot.date = row.consentDate;
                consentObject.henkilotiedot.checked = row.consentId
                  ? true
                  : false;
              }
              if (row.consentType === "palveluntiedot") {
                consentObject.palveluntiedot.id = row.consentId;
                consentObject.palveluntiedot.date = row.consentDate;
                consentObject.palveluntiedot.checked = row.consentId
                  ? true
                  : false;
              }
              if (row.consentType === "tyonhakuprofiili") {
                consentObject.tyonhakuprofiili.id = row.consentId;
                consentObject.tyonhakuprofiili.date = row.consentDate;
                consentObject.tyonhakuprofiili.checked = row.consentId
                  ? true
                  : false;
              }
            }
            handleUpdatingConsents(consentObject); //sets localstorage
          }
          setUserConsents(consentObject);
          handleLogin();
          if(consentObject?.palveluntiedot?.id) setHandleFirstNavigation("navigate") //navigates to palvelusi page if consent given
        }
      })
      .catch((error) => {
        console.log("error: " + error);
        notifyError("Jotain meni vikaan!");
      });
  };

  async function handleCallbackResponse(response) {
    setLoading(true);
    const userToken = response;
    const userObj = { userToken };

    await fetch("/api/login", {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userObj),
    })
      .then(function (response) {
        if (!response.ok) throw new Error(response.status);
        //if (response === "nopermission")
        return response.json();
      })
      .then((data) => {
        sessionStorage.setItem("groups", data.userGroups);
        sessionStorage.setItem("user", data.email);
        sessionStorage.setItem("id", data.userid);
        sessionStorage.setItem("image", data.img);
        sessionStorage.setItem("project", data.project);
        sessionStorage.setItem("rekryData", "");
        sessionStorage.setItem("userData", "");
        sessionStorage.setItem("version", "");
        if (data.dataConsent) {
          sessionStorage.setItem("dataConsent", "true");
        }
        sessionStorage.setItem("consentNeeded", data.consentNeeded)
        
        if (data.admin) {
          sessionStorage.setItem("idhash", true);
        }
        getConsent();
        getUserData();
        getRekryData();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notifyError("Jokin meni vikaan! ");
        console.log("error: " + error);
      });
  }

  async function getUserData(action) {
    let parsedData;
    let versionNumber = 15;
    let obj = { stamp: true, version: versionNumber };
    if (sessionStorage.getItem("userData") && action !== "reFetch") {
      parsedData = sessionStorage.getItem("userData");
      setUserDataFunction(parsedData);
    } else {
      await fetch("/api/user/userdata/" + sessionStorage.getItem("id") + "/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      })
        .then(function (response) {
          if (response.ok) return response.json();
        })
        .then(async function (data) {
          sessionStorage.setItem(
            "permission",
            JSON.parse(data).usePermission.value
          );
          setUserDataFunction(data);
        })
        .catch((error) => {
          console.log("error: " + error);
        });
    }
  }

  async function setUserDataFunction(data) {
    let parsedData = JSON.parse(data);
    if (parsedData.photo === "ok") {
      await fetch(
        "/api/photo/getuserphoto/" + sessionStorage.getItem("id") + "/",
        { method: "GET" }
      )
        .then(function (response) {
          if (response.ok) return response.blob();
        })
        .then((myBlob) => {
          const objectURL = URL.createObjectURL(myBlob);
          sessionStorage.setItem("userPhoto", objectURL);
          setUserPhoto(objectURL);
        });
    } else {
      sessionStorage.setItem("userPhoto", NoUser);
      setUserPhoto(NoUser);
    }

    sessionStorage.setItem(
      "name",
      parsedData?.recordFirstName?.value +
        " " +
        parsedData?.recordLastName?.value
    );

    sessionStorage.setItem("birthDate", parsedData?.birthDate?.value);
    setProjectLinks(parsedData.projectLinks);
    setUserData(parsedData);

    sessionStorage.setItem("forms", JSON.stringify(parsedData.projectForms));
    sessionStorage.setItem("userData", data);
    if (parsedData.teachingLanguage.value === "englanti") changeLanguage("en");
    else if (parsedData.teachingLanguage.value === "ruotsi")
      changeLanguage("se");
    else if (parsedData.teachingLanguage.value === "ukraina")
      changeLanguage("ua");
    else if (parsedData.teachingLanguage.value === "venäjä")
      changeLanguage("ru");
    if (!parsedData.version.value) sessionStorage.setItem("version", "0");
    else sessionStorage.setItem("version", parsedData.version.value);
  }

  async function getRekryData(reFetch) {
    let parsedData;
    if (sessionStorage.getItem("rekryData") && !reFetch) {
      parsedData = sessionStorage.getItem("rekryData");
      setRekryDataFunction(parsedData);
    } else {
      await fetch("/api/user/rekrydata/" + sessionStorage.getItem("id") + "/", {
        method: "GET",
      })
        .then(function (response) {
          if (response.ok) {
            return response.json();
          }
        })
        .then(async function (data) {
          setRekryDataFunction(data);
        })
        .catch((error) => {
          console.log("error: " + error);
        });
    }
  }

  function setRekryDataFunction(data) {
    let parsedData = JSON.parse(data);
    setRekryData(parsedData);
    setOriginalData(JSON.stringify(parsedData));
    sessionStorage.setItem("rekryData", data);
  }

  async function getWebinars() {
    await fetch("/api/misc/webinars/" + sessionStorage.getItem("id") + "/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        if (response.ok) return response.json();
      })
      .then(async function (data) {
        function sortObjectByStartDate(obj) {
          const entries = Object.entries(obj);
          const currentDate = new Date();
          entries.sort(([monthA, dataA], [monthB, dataB]) => {
            const startDateA = new Date(dataA[0].startDate);
            const startDateB = new Date(dataB[0].startDate);
            const diffA = Math.abs(startDateA - currentDate);
            const diffB = Math.abs(startDateB - currentDate);
            return diffA - diffB;
          });

          const sortedObject = Object.fromEntries(entries);
          return sortedObject;
        }
        const sortedArrays = sortObjectByStartDate(data);
        setWebinars(sortedArrays);
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  }

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <DataConsentForm
          show={showConsentForm}
          setShow={setShowConsentForm}
          userConsents={userConsents}
          setUserConsents={setUserConsents}
          handleLogOut={handleLogOut}
          handleLogin={handleLogin}
          handleUpdatingConsents={handleUpdatingConsents}
          setHandleFirstNavigation={setHandleFirstNavigation}
        />

        <Navigation
          changeLanguage={changeLanguage}
          handleLogOut={handleLogOut}
          setInsModal={setInsModal}
          setShowConsentForm={setShowConsentForm}
          userConsents={userConsents}
          login={login}
          userPhoto={userPhoto}
        />
        <ToastContainer />

        <Routes>
          <Route
            path="/"
            element={
              <Home
                loading={loading}
                setInsModal={setInsModal}
                handleCallbackResponse={handleCallbackResponse}
                login={login}
                webinars={webinars}
                handleFirstNavigation={handleFirstNavigation}
                setHandleFirstNavigation={setHandleFirstNavigation}
              />
            }
          />

          <Route
            path="palvelusi"
            element={
              <PrivateRoute login={login} userConsents={userConsents}>
                <WelcomePage
                  getUserData={getUserData}
                  webinars={webinars}
                  getWebinars={getWebinars}
                  userData={userData}
                  setUserData={setUserData}
                  rekryData={rekryData}
                  setRekryData={setRekryData}
                  projectLinks={projectLinks}
                  setProjectLinks={setProjectLinks}
                />
              </PrivateRoute>
            }
          />

          <Route
            path="omat-tiedot"
            element={
              <PrivateRoute login={login} userConsents={userConsents}>
                <OmatTiedot />
              </PrivateRoute>
            }
          />

          <Route
            path="hakuprofiili"
            element={
              <PrivateRoute login={login} userConsents={userConsents}>
                <HakuProfiili
                  rekryData={rekryData}
                  getRekryData={getRekryData}
                  setRekryData={setRekryData}
                  userData={userData}
                  getUserData={getUserData}
                  originalData={originalData}
                />
              </PrivateRoute>
            }
          />

          <Route
            path="palaute"
            element={
              <PrivateRoute login={login} userConsents={userConsents}>
                <Palaute />
              </PrivateRoute>
            }
          />

          <Route
            path="tiedostot"
            element={
              <PrivateRoute login={login} userConsents={userConsents}>
                <Tiedostot />
              </PrivateRoute>
            }
          />

          <Route
            path="lomakkeet"
            element={
              <PrivateRoute login={login} userConsents={userConsents}>
                <Forms />
              </PrivateRoute>
            }
          />

          <Route
            path="mita-uutta"
            element={
              <PrivateRoute login={login} userConsents={userConsents}>
                <WhatsNew />
              </PrivateRoute>
            }
          />

          <Route
            path="tyopaikka"
            element={
              <PrivateRoute login={login} userConsents={userConsents}>
                <ContractPage />
              </PrivateRoute>
            }
          />

          <Route path="acceptRequest" element={<AcceptRequest />} />

          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>There's nothing here!</p>
              </main>
            }
          />
        </Routes>
        <Modal
          size="lg"
          className="insModal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={insModal}
          onHide={() => setInsModal(false)}
        >
          <Modal.Header style={{ alignSelf: "center" }} closeButton>
            <Modal.Title>
              <h3>{t("etusivuOhjeet.title")}</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="indexNoteOpen">
              <br />
              <iframe
                src={
                  "https://docs.google.com/document/d/e/2PACX-1vRo_j1c-mpHDSfbZUwmbdn75Ed2NAwOGkxTGavp_mNlmaoEfhpmMFL4DHNeoUAOaUBRRPkEL3ejSLBH/pub?embedded=true"
                }
                style={{
                  border: "none",
                  width: "100%",
                  height: "70vh",
                  padding: "none",
                }}
                title="Embedded Google Doc"
              >
                <CircularProgress />
              </iframe>
              <br />
              <Button id="loginIns" onClick={() => setInsModal(false)}>
                {t("etusivuOhjeet.close")}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        <Footer />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
