import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Modal,
  FormControlLabel,
  Box,
  Checkbox,
  Backdrop,
  Fade,
  CircularProgress,
} from "@mui/material";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //width: 400,
  bgcolor: "white",
  // border: '2px solid #000',
  boxShadow: 24,
  borderRadius: "15px",
  p: 4,
  backgroundColor: "white !important",
  width: { xs: "80vw", sm: "80vw", md: "50vw" },
};

const notifyError = (msg) =>
  toast.error(msg, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

const DataConsentForm = ({
  show,
  setShow,
  userConsents,
  setUserConsents,
  handleLogOut,
  handleLogin,
  handleUpdatingConsents,
  setHandleFirstNavigation
}) => {
  const { t } = useTranslation();
  const [newConsents, setNewConsents] = useState(userConsents);
  const [loading, setLoading] = useState(false);
  const fadeIn = show ? true : false;

  useEffect(() => {
    setNewConsents(userConsents);
  }, [userConsents]);

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setNewConsents((prevConsents) => ({
      ...prevConsents,
      [name]: {
        ...prevConsents[name],
        checked: checked,
        date: new Date().toLocaleDateString("FI-fi"),
      },
    }));
  };

  const handleLogOutAndCloseModal = () => {
    handleLogOut();
    setShow(false);
  };

  const saveDataConsents = () => {
    setLoading(true);
    fetch("/api/consents/SaveDataConsents/" + sessionStorage.getItem("id"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newConsents),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((response) => {
        setUserConsents(response);
        handleUpdatingConsents(response)
        if (!sessionStorage.getItem("dataConsent"))
          handleIsConsentsGiven("Kyllä");
        sessionStorage.setItem("dataConsent", "true");
        setShow(false);
        if (!["navbar", "palvelusi", "tyonhakuprofiili"].includes(show)) {
          handleLogin();
          setHandleFirstNavigation("navigate")
        }
      })
      .catch((error) => {
        console.log("error: " + error);
        notifyError("Jokin meni vikaan tallennettaessa suostumuksia!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function handleIsConsentsGiven(status) {
    if (status) {
      const isGiven = status;
      fetch("/api/consents/updateIsConsentsGiven/:id/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ isGiven }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Status päivitetty:", data);
        })
        .catch((error) => {
          notifyError("Jokin meni vikaan tallennettaessa suostumuksia!");
          console.error("Virhe päivityksessä:", error);
        });
    }
  }

  return (
    <>
      <Modal
        open={show}
        onClose={() => setShow(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{}}
      >
        <Fade in={fadeIn}>
          <Box
            sx={{
              ...style,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "fit-content",
            }}
          >
            <Typography variant="h6" sx={{ padding: "20px 30px 20px 40px" }}>
              {t("dataconsents.title")}
            </Typography>

            <Box mb={3}>
              <Typography sx={{ padding: "10px 30px 0px 40px" }}>
                {t("dataconsentlogin.p1")}
              </Typography>
            </Box>

            <Box mb={3} sx={{ paddingLeft: "40px" }}>
              <a
                href="https://www.arffman.fi/tietosuoja/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "black" }}
              >
                <span>{t("dataconsents.link")}</span>
              </a>
            </Box>

            <Box component="form" sx={{ padding: "20px 0 20px 40px" }}>
              <Typography sx={{ paddingBottom: "30px" }}>
                {t("dataconsentlogin.consentsTitleText")}
              </Typography>

              <Box sx={{ marginBottom: "20px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={true}
                      onChange={handleChange}
                      disabled
                      name="personalData"
                    />
                  }
                  label={t("dataconsents.consent1")}
                />
                   <Typography>
                  {userConsents?.henkilotiedot?.checked &&
                  userConsents?.henkilotiedot?.date
                    ? "Lupa annettu: " + userConsents?.henkilotiedot?.date
                    : null}
                </Typography>
              </Box>
              <Box sx={{ marginBottom: "20px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={newConsents.palveluntiedot.checked}
                      onChange={handleChange}
                      name="palveluntiedot"
                    />
                  }
                  label={
                    <Typography
                      color={show === "palvelusi" ? "error" : "inherit"}
                    >
                      {t("dataconsents.consent4")}
                    </Typography>
                  }
                />
                <Typography>
                  {userConsents?.palveluntiedot?.checked &&
                  userConsents?.palveluntiedot?.date
                    ? "Lupa annettu: " + userConsents?.palveluntiedot?.date
                    : null}
                </Typography>
              </Box>
              <Box sx={{ marginBottom: "20px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={newConsents.tyonhakuprofiili.checked}
                      onChange={handleChange}
                      name="tyonhakuprofiili"
                    />
                  }
                  label={
                    <Typography
                      color={show === "tyonhakuprofiili" ? "error" : "inherit"}
                    >
                      {t("dataconsents.consent2")}
                    </Typography>
                  }
                />
                <Typography>
                  {userConsents?.tyonhakuprofiili?.checked &&
                  userConsents?.tyonhakuprofiili?.date
                    ? "Lupa annettu: " + userConsents?.tyonhakuprofiili?.date
                    : null}
                </Typography>
              </Box>

              <Box sx={{ marginBottom: "20px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={newConsents.tyosuhdetiedot.checked}
                      onChange={handleChange}
                      name="tyosuhdetiedot"
                    />
                  }
                  label={t("dataconsents.consent3")}
                />
                <Typography>
                  {userConsents?.tyosuhdetiedot?.checked &&
                  userConsents?.tyosuhdetiedot?.date
                    ? "Lupa annettu: " + userConsents?.tyosuhdetiedot?.date
                    : null}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                padding: "20px",
                display: "flex",
                justifyContent: "center",
                gap: 2,
                height: "85px",
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                sx={{ width: "220px" }}
                onClick={loading ? null : saveDataConsents}
              >
                {loading ? (
                  <CircularProgress size={26} />
                ) : (
                  t("dataconsents.ButtonSave")
                )}
              </Button>
              {!["navbar", "palvelusi", "tyonhakuprofiili"].includes(show) && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleLogOutAndCloseModal}
                >
                  {t("dataconsentlogin.ButtonLogOut")}
                </Button>
              )}
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default DataConsentForm;
