export const versionHistory = [
  {
    header: "Profiilikuvan lisäys",
    text: "Voit lisätä/poistaa oman profiilikuvan painamalla Omat tiedot-sivulta löytyvää kuvaketta.",
    pvm: "02.2.2023",
    versio: 1,
  },
  {
    header: "Käyttöohjeet",
    text: "Käyttöohjeiden avulla portaalin käyttö helpottuu. Löydät käyttöhjeet painamalla eri toimintojen läheisyydestä löytyvää info-nappia.",
    pvm: "02.2.2023",
    versio: 1,
  },
  {
    header: "Mitä uutta?",
    text: "Mitä uutta? -ominaisuus lisätty. Näet mitä uutta portaaliin on lisätty viime kirjautumisesi jälkeen.",
    pvm: "02.2.2023",
    versio: 1,
  },
];
