import { React, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import LoadingSpinner from "./Misc/LoadingSpinner";
import { Check, XCircleFill } from "react-bootstrap-icons";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import "../i18n";

function NameChange({
  show,
  handleCloseModal,
  setContinueNameChange,
  notifyError,
  notifySuccess,
  handleSubmit,
}) {
  const { t, i18n } = useTranslation();

  function handleSubmitfnc() {
    setContinueNameChange(true);
    handleSubmit(true);
    handleCloseModal();
  }

  return (
    <Modal
      size="m"
      className="deviceModal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={handleCloseModal}
    >
      <Modal.Header style={{ alignSelf: "center" }}>
        <Modal.Title>{t("nameChange")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <button
              onClick={handleSubmitfnc}
              style={{
                borderStyle: "none",
                background: "none",
                margin: "0 7.5vh 0 7.5vh",
              }}
            >
              {" "}
              <Check size="50" color="green" />
            </button>{" "}
          </div>
          <div>
            <button
              onClick={handleCloseModal}
              style={{
                borderStyle: "none",
                background: "none",
                marginTop: "5px",
              }}
            >
              {" "}
              <XCircleFill size="40" color="red" />
            </button>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

export default NameChange;
