import { React, useEffect, useState, useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Logout from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import LanguageIcon from "@mui/icons-material/Language";
import { Link } from "react-router-dom";
import arffman from "../images/arffman.png";
import finImg from "../images/fin.png";
import sweImg from "../images/swe.png";
import engImg from "../images/eng.png";
import ukrImg from "../images/ukr.png";
import rusImg from "../images/rus.png";
import ggle from "../images/ggle.webp";

const Navigation = ({
  changeLanguage,
  handleLogOut,
  setInsModal,
  setShowConsentForm,
  login,
  userConsents,
}) => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorLangNav, setAnchorLangNav] = useState(null);
  const loggedIn = sessionStorage.getItem("goASifkFD4235");
  const consentNeeded = sessionStorage.getItem("consentNeeded") === "true";
  
  const navigate = useNavigate();
  const handleYourInfoClick = () => {
    handleClose();
    navigate("/omat-tiedot");
  };

  const handleFeedbackClick = () => {
    handleClose();
    navigate("/palaute");
  };

  const handleWhatsNewClick = () => {
    handleClose();
    navigate("/mita-uutta");
  };

  const handleFilesClick = () => {
    handleClose();
    navigate("/tiedostot");
  };
  const handleInformClick = () => {
    handleClose();
    navigate("/tyopaikka");
  };

  const handleFormClick = () => {
    handleClose();
    navigate("/lomakkeet");
  };
  const handleConsentsClick = () => {
    handleClose();
    setShowConsentForm("navbar");
  };


  const open = Boolean(anchorEl);
  const langOpen = Boolean(anchorLangNav);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenLangMenu = (event) => {
    setAnchorLangNav(event.currentTarget);
  };

  const handleCloseLangMenu = () => {
    setAnchorLangNav(null);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const uri = window?.location?.href?.split("?")[0] || window.location.href;

  return (
    <AppBar position="sticky" sx={{ backgroundColor: "white", zIndex: 1000 }}>
      <Container
        maxWidth="xl"
        sx={{ maxWidth: "20000px!important", height: "4rem" }}
      >
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "black",
              textDecoration: "none",
            }}
          >
            <img id="arfLogo" src={arffman} alt="Arffman" />
          </Typography>
          {loggedIn ? (
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="black"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {!userConsents?.palveluntiedot?.id && consentNeeded ? (
                  <MenuItem
                    onClick={() => {
                      handleCloseNavMenu();
                      setShowConsentForm("palvelusi");
                    }}
                  >
                    <Typography color="textDisabled" textAlign="center">
                      Palvelusi
                    </Typography>
                  </MenuItem>
                ) : (
                  <MenuItem
                    onClick={handleCloseNavMenu}
                    component={Link}
                    to="palvelusi"
                  >
                    <Typography color="textDisabled" textAlign="center">
                      Palvelusi
                    </Typography>
                  </MenuItem>
                )}
                {!userConsents?.tyonhakuprofiili?.id && consentNeeded ? (
                  <MenuItem
                    onClick={() => {
                      handleCloseNavMenu();
                      setShowConsentForm("tyonhakuprofiili");
                    }}
                  >
                    <Typography color="textDisabled" textAlign="center">
                      Työnhaku
                    </Typography>
                  </MenuItem>
                ) : (
                  <MenuItem
                    onClick={handleCloseNavMenu}
                    component={Link}
                    to="hakuprofiili"
                  >
                    <Typography textAlign="center">Työnhaku</Typography>
                  </MenuItem>
                )}
              </Menu>
            </Box>
          ) : (
            <Box
              sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
            ></Box>
          )}
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {loggedIn ? (
              <>
                <Typography
                  variant="subtitle1"
                  noWrap
                  component="a"
                  onClick={(e) => {
                    if (!userConsents?.palveluntiedot?.id && consentNeeded) {
                      setShowConsentForm("palvelusi");
                    } else {
                      navigate("/palvelusi");
                    }
                  }}
                  color={userConsents?.palveluntiedot?.id || !consentNeeded ? "black" : "text.disabled"}
                  sx={{
                    mr: 2,
                    fontWeight: 400,
                    textDecoration: "none",
                    textTransform: "none",
                    borderBottom: "2px solid transparent",
                    transition: "color 0.3s, borderBottom 0.3s",
                    cursor: "pointer",
                  }}
                >
                  Palvelusi
                </Typography>

                <Typography
                  variant="subtitle1"
                  noWrap
                  component="a"
                  onClick={(e) => {
                    
                    if (!userConsents?.tyonhakuprofiili?.id && consentNeeded) {
                      console.log('what: ', !userConsents?.tyonhakuprofiili?.id && consentNeeded, !userConsents?.tyonhakuprofiili?.id , consentNeeded);
                     
                        console.log("This is a boolean", typeof !userConsents?.tyonhakuprofiili?.id && consentNeeded);
                      
                      setShowConsentForm("tyonhakuprofiili");
                    } else {
                      console.log('?????');
                      
                      navigate("/hakuprofiili");
                    }
                  }}
                  color={userConsents?.tyonhakuprofiili?.id || !consentNeeded ? "black" : "text.disabled"}
                  sx={{
                    mr: 2,
                    fontWeight: 400,
                    textDecoration: "none",
                    textTransform: "none",
                    borderBottom: "2px solid transparent",
                    cursor: "pointer",
                    transition: "color 0.3s, borderBottom 0.3s",
                  }}
                >
                  Työnhaku
                </Typography>
              </>
            ) : (
              <></>
            )}
          </Box>
          <Stack direction="row-reverse">
            {loggedIn ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Tooltip title="Account settings">
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      sx={{ ml: 2 }}
                      aria-controls={open ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                    >
                      <Avatar
                        sx={{ width: 32, height: 32 }}
                        src={sessionStorage.getItem("userPhoto")}
                      ></Avatar>
                    </IconButton>
                  </Tooltip>
                </Box>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem onClick={handleYourInfoClick}>
                    <Avatar src={sessionStorage.getItem("userPhoto")} /> Omat
                    tiedot
                  </MenuItem>

                  <Divider />
                  <MenuItem onClick={handleFormClick}>Lomakkeet</MenuItem>
                  <MenuItem onClick={handleFeedbackClick}>
                    Anna palautetta
                  </MenuItem>

                  <MenuItem onClick={handleFilesClick}>Tiedostot</MenuItem>
                  <MenuItem onClick={handleInformClick}>
                    Ilmoita työllistymisestä
                  </MenuItem>
                  <MenuItem onClick={handleWhatsNewClick}>Mitä uutta</MenuItem>
                  <MenuItem onClick={handleConsentsClick}>
                    Tietojenkäsittelyluvat
                  </MenuItem>
                  <MenuItem onClick={handleLogOut}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Kirjaudu ulos
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <>
                <a
                  href={
                    "https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=396899188355-255v29dh0hktattha7epme2knll6rqm1.apps.googleusercontent.com&scope=openid%20email%20profile&redirect_uri=" +
                    uri +
                    "&access_type=offline&prompt=select_account"
                  }
                  style={{
                    textDecoration: "none",
                    backgroundColor: "white", // Google Blue
                    color: "black",
                    fontSize: "0.9rem",
                    padding: "10px 12px",
                    borderRadius: "4px",
                    display: "inline-block",
                    fontFamily: "'Roboto', sans-serif", // Google's font
                    fontWeight: "500",
                    boxShadow: "0 2px 4px 0 rgba(0,0,0,.25)",
                    // border: "solid grey",
                    cursor: "pointer",
                    textAlign: "center",
                    alignContent: "center",
                    marginLeft: "10px",
                  }}
                >
                  Kirjaudu sisään{" "}
                  <img alt="googleicon" style={{ width: "15px", heigth: "15px" }} src={ggle} />
                </a>
              </>
            )}
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => setInsModal(true)}
              color="black"
              sx={{ marginLeft: "0.5rem" }}
            >
              <QuestionMarkIcon />
            </IconButton>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenLangMenu}
              color="black"
            >
              <LanguageIcon />
            </IconButton>

            <Menu
              anchorEl={anchorLangNav}
              id="language select"
              open={langOpen}
              onClose={handleCloseLangMenu}
              onClick={handleCloseLangMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 0,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={() => changeLanguage("fi")}>
                <Avatar src={finImg} />
              </MenuItem>
              <MenuItem onClick={() => changeLanguage("se")}>
                <Avatar src={sweImg} />
              </MenuItem>
              <MenuItem onClick={() => changeLanguage("en")}>
                <Avatar src={engImg} />
              </MenuItem>
              <MenuItem onClick={() => changeLanguage("ua")}>
                <Avatar src={ukrImg} />
              </MenuItem>
              <MenuItem onClick={() => changeLanguage("ru")}>
                <Avatar src={rusImg} />
              </MenuItem>
            </Menu>
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navigation;
