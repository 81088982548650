import React, { useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
} from "@mui/material";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const MeetingsTable = ({ meetings, show, handleCloseMeetings, openMeetings }) => {
  const [showUpcoming, setShowUpcoming] = useState(true);
  const [showPast, setShowPast] = useState(false);
  const { t } = useTranslation();

  // Suodatetaan tapaamiset valittujen suodattimien mukaan
  const filteredMeetings = meetings.filter((meeting) => {
    const meetingDate = new Date(meeting.meetingDate);
    const now = new Date();

    if (showUpcoming && meetingDate > now) return true;
    if (showPast && meetingDate <= now) return true;

    return false;
  });

  // Muotoillaan päivämäärä
  const formatDate = (dateTime) => {
    return new Date(dateTime).toLocaleString("fi-FI", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  };

  return (
    <Modal
      size="lg"
      className="insModal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={handleCloseMeetings}
    >
      <Modal.Header style={{ padding: "40px 50px 20px 50px" }} closeButton>
        <Modal.Title>
          <h3>{t("meetings.title2")}</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "20px 50px 50px 50px", minHeight: "450px" }}>
        <FormControlLabel
          style={{ marginRight: "30px" }}
          control={
            <Checkbox
              checked={showUpcoming}
              onChange={() => setShowUpcoming(!showUpcoming)}
            />
          }
          label={t("meetings.checkbox1")}
        />
        <FormControlLabel
          style={{ marginRight: "30px" }}
          control={
            <Checkbox
              checked={showPast}
              onChange={() => setShowPast(!showPast)}
            />
          }
          label={t("meetings.checkbox2")}
        />

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("meetings.tablecell1")}</TableCell>
              <TableCell>{t("meetings.tablecell2")}</TableCell>
              <TableCell>{t("meetings.tablecell3")}</TableCell>
              <TableCell>{t("meetings.tablecell4")}</TableCell>
              <TableCell>{t("meetings.tablecell5")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredMeetings?.map((meeting, index) => (
              <TableRow key={index}>
                <TableCell>{meeting.meetingTitle}</TableCell>
                <TableCell>{meeting.serviceType}</TableCell>
                <TableCell>
                  {formatDate(new Date(meeting.meetingDate), "yyyy-MM-dd HH:mm")}
                </TableCell>
                <TableCell>{meeting.hours}</TableCell>
                <TableCell>{meeting.owner}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};

export default MeetingsTable;