import React from "react";
import { XLg } from "react-bootstrap-icons";

function RadioButton({
  userData,
  editSingleSelect,
  handleSubmit,
  cancelEdit,
  editFunction,
  field,
}) {
  // console.log("ratiobutton", userData)
  return (
    <>
      {userData && userData[field] ? (
        <>
          {userData && userData[field].checked ? (
            <>
              <div style={{ display: "flex", justifyContent: "right" }}>
                <div className="dropDownDiv">
                  <div style={{ textAlign: "right" }}>
                    <select
                      onChange={(e) =>
                        editSingleSelect(e.target.value, userData[field])
                      }
                    >
                      <option value="">{sessionStorage.getItem("lang") === "fi" ? "Valitse" : "Select"}</option>
                      <option value="Kyllä">{sessionStorage.getItem("lang") === "fi" ? "Kyllä" : "Yes"}</option>
                      <option value="Ei">{sessionStorage.getItem("lang") === "fi" ? "Ei" : "No"}</option>
                    </select>
                  </div>
                  <div style={{ alignSelf: "center" }}>
                    <button
                      className="cancelButton"
                      onClick={(e) => cancelEdit(userData[field])}
                    >
                      <XLg size={"20"} />
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <button
              onClick={(e) => editFunction(userData[field])}
              style={{ background: "none", borderRadius: "8px" }}
            >
              {userData[field].value}
            </button>
          )}
        </>
      ) : (
        <>
          <button
            onClick={(e) => editFunction(userData[field])}
            style={{ background: "none", borderRadius: "8px" }}
          >
            -
          </button>
        </>
      )}
    </>
  );
}

export default RadioButton;
