import React, {
  useState,
  useEffect,
  useCallback,
  createContext,
  useRef,
} from "react";
import FileDropZone from "../components/FileAdd/FileDropZone";
import LoadingSpinner from "../components/Misc/LoadingSpinner";
import { toast } from "react-toastify";
import DocsEmbed from "../components/GoogleDocs/DocsEmbed";
import { ColumnsGap, InfoCircleFill } from "react-bootstrap-icons";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import "../i18n";
import { Button, Modal, Table } from "react-bootstrap";
import DeleteIcon from "../images/deleteicon.png";
import AddIcon from "../images/plus.png";
import { Tooltip } from "react-tippy";
import { Typography, Paper } from "@mui/material";

function Tiedostot() {
  const { t, i18n } = useTranslation();
  const [pickedFile, setpickedFile] = useState();
  const [userFiles, setUserFiles] = useState({
    cv: [],
    application: [],
    other: [],
  });
  const [omatTiedotShow, setOmatTiedotShow] = useState(false);
  const [sendLoader, setSendLoader] = useState(true);
  const [imgUrl, setImgUrl] = useState(null);
  const [cvModal, setCvModal] = useState(false);
  const [foundCvNewData, setFoundCvNewData] = useState([]);
  const [loadingIndices, setLoadingIndices] = useState([]);
  const [dataToSend, setDataToSend] = useState({});
  const [userData, setUserData] = useState(
    sessionStorage.getItem("userData") ? sessionStorage.getItem("userData") : {}
  );
  const [rekryData, setRekryData] = useState(
    sessionStorage.getItem("rekryData")
      ? sessionStorage.getItem("rekryData")
      : {}
  );

  const optionObject = {
    email: t("tiedostot.modal.email"),
    phone: t("tiedostot.modal.phone"),
    languages: t("tiedostot.modal.languages"),
    empty: "-",
  };

  const notifyDeleteSuccess = () =>
    toast.success("Tiedosto poistettu!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifyDynamicSucc = (msg) =>
    toast.success(msg, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifyDynamicErr = (msg) =>
    toast.error(msg, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifyAddSuccess = () =>
    toast.success("Tiedosto tallennettu!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifyAddError = () =>
    toast.error(
      "Virhe tiedoston lisäyksessä: Yritä uudelleen tai ota yhteyttä palveluasiantuntijaasi.",
      {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );

  const notifyDeleteError = () =>
    toast.error(
      "Virhe tiedoston poistossa: Yritä uudelleen tai ota yhteyttä palveluasiantuntijaasi.",
      {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );

  function getFiles() {
    fetch("/api/files/filedata/" + sessionStorage.getItem("id"))
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        //console.log(data);
        let cvArr = [];
        let appArr = [];
        let othArr = [];

        for (let i = 0; i < data.length; i++) {
          if (data[i].name.includes("Ansioluettelo")) {
            cvArr.push(data[i]);
          } else if (data[i].name.includes("Työhakemus")) {
            appArr.push(data[i]);
          } else if (data[i].name.includes("Muu")) {
            othArr.push(data[i]);
          }
        }
        //console.log(cvArr, appArr, othArr);

        let newUserFiles = { ...userFiles };

        newUserFiles.cv = cvArr;
        newUserFiles.application = appArr;
        newUserFiles.other = othArr;

        setUserFiles(newUserFiles);
      });
  }

  const isLoading = (index) => {
    return loadingIndices.includes(index);
  };

  async function deleteFile(file, index) {
    //console.log('file', file);
    setLoadingIndices([...loadingIndices, index]);
    await fetch("/api/files/filedelete/" + sessionStorage.getItem("id"), {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(file),
    }).then(function (response) {
      if (response.ok) {
        getFiles();
        notifyDeleteSuccess();
        let newIndices = [...loadingIndices];
        newIndices.splice(index, 1);
        setLoadingIndices(newIndices);
      } else {
        //console.log('ei toimi');
        let newIndices = [...loadingIndices];
        newIndices.splice(index, 1);
        setLoadingIndices(newIndices);
        notifyDeleteError();
      }
    });
  }

  const uploadFiles = (files) => {
    setSendLoader(false);
    let userData = JSON.parse(sessionStorage.getItem("userData"));
    let projectName = userData.projectName.value;
    let projectId = userData.projectId.value;
    let fullName =
      userData.recordFirstName.value + userData.recordLastName.value;
    let language = document.getElementById("langSelect").value;
    let type = document.getElementById("typeSelect").value;

    let infoObj = { projectName, projectId, fullName, language, type };

    const formData = new FormData();
    formData.append("fileInfo", JSON.stringify(infoObj));
    formData.append("file", pickedFile);

    const response = fetch(
      "/api/files/filesend/" + sessionStorage.getItem("id"),
      {
        method: "POST",
        body: formData,
        headers: {},
      }
    )
      .then(function (response) {
        return response;
      })
      .then(function (data) {
        setSendLoader(true);
        setpickedFile("");
        setImgUrl(null);
        let dataArr = [];
        let oldData = {};
        let newData = {};

        getFiles();
        notifyAddSuccess();
      })
      .catch((error) => {
        console.log("error: " + error);
        notifyAddError();
        setSendLoader(false);
      });
  };

  console.log("filesender;", sendLoader);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      let imgSrc = URL.createObjectURL(file);

      setImgUrl(imgSrc);

      setpickedFile(file);
      return file;
    });
  }, []);

  useEffect(() => {
    getFiles();
  }, []);

  function saveCvData() {
    console.log("Data to send:", dataToSend);
    let sendToBack = true;
    for (let i = 0; i < dataToSend.length; i++) {
      if (dataToSend[i].type === "empty") {
        console.log("Tyhjää!!!");
        notifyDynamicErr(() => {
          t("tiedostot.modal.dynamicErr");
        });
        sendToBack = false;
      }

      //{t('rekry.tyopaikat.yritys')}
      if (dataToSend[i].type === "email") {
        dataToSend[i].apiName = "Email";
      }
      if (dataToSend[i].type === "phone") {
        dataToSend[i].apiName = "Mobile";
      }
    }

    if (sendToBack) {
      let obj = { dataToSend };
      const response = fetch(
        "/api/files/cvDataSave/" + sessionStorage.getItem("id"),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(obj),
        }
      )
        .then(function (response) {
          return response;
        })
        .then(async function (data) {
          console.log(data);
          await fetch(
            "/api/user/userdata/" + sessionStorage.getItem("id") + "/",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
            .then(function (response) {
              //console.log(response)
              if (response.ok) {
                return response.json();
              }
            })
            .then(async function (data) {
              sessionStorage.setItem("userData", data);
              setCvModal(false);
              let text = t("tiedostot.modal.dynamicSucc");
              notifyDynamicSucc(text);
            })
            .catch((error) => {
              console.log("error: " + error);
            });
        })
        .catch((error) => {
          console.log("error: " + error);
        });
    }
  }

  function handleDataDelete(i) {
    let newData = [...foundCvNewData];
    let oldData = [...foundCvNewData];

    newData.splice(i, 1);
    console.log("uusi:", newData);

    oldData[i].toSend = false;
    console.log("Old data:", oldData);

    setDataToSend(newData);
    setFoundCvNewData(oldData);
  }

  function handleDataAdd(i) {
    let newData = [...foundCvNewData];
    newData[i].toSend = true;
    setFoundCvNewData(newData);
    let toSendNew = [...dataToSend];
    console.log("Vanha tosend: ", toSendNew);

    toSendNew[i] = newData[i];
    console.log("Uusi tosend:", toSendNew);
    setDataToSend(toSendNew);
  }

  function handleSelectChange(item, i) {
    let ele = document.getElementById("dataSelect" + i);
    let value = ele.value;

    console.log(item, value, i);

    let newArr = [...foundCvNewData];

    for (let x = 0; x < newArr.length; x++) {
      if (newArr[x].type === value) {
        newArr[x].type = "empty";
      }
    }

    newArr[i].type = value;

    console.log(newArr);

    setFoundCvNewData(newArr);
    setDataToSend(newArr);
  }

  useEffect(() => {
    let selects = document.getElementsByTagName("select");
    for (let j = 0; j < selects.length; j++) {
      console.log(selects[j].value);
      if (selects[j].value === "empty") {
        selects[j].style.border = "solid thin red";
      } else {
        selects[j].style.border = "solid thin black";
      }
    }
  }, [foundCvNewData]);

  function handleChange(i, e) {
    let newData = [...foundCvNewData];
    newData[i].new = e.target.value;
    console.log("Muokattu data:", newData[i].new);
    setDataToSend(newData);
    setFoundCvNewData(newData);
  }
  /*
 <a style={{marginLeft:'5px'}}
            className="header-info-link"
            onClick={() => setOmatTiedotShow(true)}
          >
            <InfoCircleFill color="black" size="18" />
          </a>
*/
  return (
    <div id="tableContainer">
      <Paper
        sx={{
          padding: "50px",
          width: "80vw",
          paddingTop: "10px",
          paddingBottom: "70px",
          marginTop: "30px",
        }}
        elevation={3}
      >
        <div className="percentH2Div">
          <Typography sx={{ marginBottom: "15px" }} variant="h3">
            {t("tiedostot.title")}
          </Typography>
        </div>
        {omatTiedotShow ? (
          <DocsEmbed
            setOmatTiedotShow={setOmatTiedotShow}
            show={omatTiedotShow}
            elementId={"_4gzgxp32ka1d"}
          />
        ) : (
          <></>
        )}
        <div id="cvDiv">
          <div style={{ width: "50%" }} className="drop-zone">
            <FileDropZone maxFiles={1} onDrop={onDrop} img={imgUrl} />
          </div>
          <div style={{ marginTop: "2rem" }}>
            <div className="selectLangType">
              <h4>{t("tiedostot.languageSelectText")}</h4>
              <div className="form-group" id="formDiv">
                <div id="fileSelDiv" style={{ marginTop: "1rem" }}>
                  <select
                    className="fileSelect"
                    name="language"
                    id="langSelect"
                  >
                    <option value="FI">{t("tiedostot.langSelect1")}</option>
                    <option value="EN">{t("tiedostot.langSelect2")}</option>
                    <option value="SE">{t("tiedostot.langSelect3")}</option>
                  </select>

                  <select className="fileSelect" name="type" id="typeSelect">
                    <option value="Ansioluettelo">
                      {t("tiedostot.select1")}
                    </option>
                    <option value="Työhakemus">{t("tiedostot.select2")}</option>
                    <option value="Muu">{t("tiedostot.select3")}</option>
                  </select>
                </div>
              </div>

              {sendLoader ? (
                <button
                  className="btn btn-default"
                  id="submitFile"
                  style={{
                    marginTop: "0",
                    backgroundColor: "#7DB5DA",
                    paddingTop: "0.25rem",
                  }}
                  onClick={() => uploadFiles(pickedFile)}
                >
                  {t("tiedostot.send")}
                </button>
              ) : (
                <button
                  className="btn btn-default"
                  id="submitFile"
                  style={{
                    marginTop: "0",
                    backgroundColor: "#7DB5DA",
                    paddingTop: "0.25rem",
                  }}
                >
                  <LoadingSpinner type={"small"} />
                </button>
              )}

              <Modal
                size="lg"
                className="deviceModal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={cvModal}
                onHide={() => setCvModal(false)}
              >
                <Modal.Header style={{ alignSelf: "center" }}>
                  <Modal.Title>{t("tiedostot.modal.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="cvInputDiv">
                    <p>{t("tiedostot.modal.subTitle")}</p>
                    <Table striped>
                      <thead>
                        <th></th>
                        <th>{t("tiedostot.modal.dataType")}</th>
                        <th>{t("tiedostot.modal.newData")}</th>
                        <th>{t("tiedostot.modal.oldData")}</th>
                      </thead>
                      <tbody>
                        {foundCvNewData.map((item, i) => (
                          <>
                            {item.toSend ? (
                              <>
                                <tr>
                                  <td>
                                    <img
                                      onClick={() => handleDataDelete(i)}
                                      className="deleteIcon"
                                      src={DeleteIcon}
                                    />
                                  </td>

                                  <td>
                                    <select
                                      id={"dataSelect" + i}
                                      className="cvSelectField"
                                      onChange={() =>
                                        handleSelectChange(item, i)
                                      }
                                    >
                                      {Object.keys(optionObject).map((val) =>
                                        val === item.type ? (
                                          <>
                                            <option value={val} selected>
                                              {optionObject[val]}
                                            </option>
                                          </>
                                        ) : (
                                          <>
                                            <option value={val}>
                                              {optionObject[val]}
                                            </option>
                                          </>
                                        )
                                      )}
                                    </select>
                                  </td>
                                  <td>
                                    <input
                                      className="cvInputField"
                                      onChange={(e) => handleChange(i, e)}
                                      defaultValue={item.new}
                                    ></input>
                                  </td>
                                  <td>
                                    <p className="cvOldDataField">
                                      {item.old ? item.old : "-"}
                                    </p>
                                  </td>
                                </tr>
                              </>
                            ) : (
                              <>
                                <tr
                                  style={{
                                    backgroundColor: "rgba(128, 128, 128, 0.5)",
                                  }}
                                >
                                  <td>
                                    <img
                                      onClick={() => handleDataAdd(i)}
                                      className="deleteIcon"
                                      src={AddIcon}
                                    />
                                  </td>

                                  <td>
                                    <select disabled className="cvSelectField">
                                      {Object.keys(optionObject).map((val) =>
                                        val === item ? (
                                          <>
                                            <option value={val} selected>
                                              {optionObject[val]}
                                            </option>
                                          </>
                                        ) : (
                                          <>
                                            <option value={val}>
                                              {optionObject[val]}
                                            </option>
                                          </>
                                        )
                                      )}
                                    </select>
                                  </td>
                                  <td>
                                    <input
                                      disabled
                                      className="cvInputField"
                                      onChange={(e) => handleChange(i, e)}
                                      value={item.new}
                                    ></input>
                                  </td>
                                  <td>
                                    <p
                                      className="cvOldDataField"
                                      style={{ color: "dimgray" }}
                                    >
                                      {item.old ? item.old : "-"}
                                    </p>
                                  </td>
                                </tr>
                              </>
                            )}
                          </>
                        ))}
                      </tbody>
                    </Table>
                    <div className="cvDataSaveDiv">
                      <button
                        className="btn btn-default"
                        id="submitFile"
                        style={{
                          marginTop: "0",
                          backgroundColor: "#7DB5DA",
                          paddingTop: "0.25rem",
                          fontSize: "1.1rem",
                        }}
                        onClick={saveCvData}
                      >
                        {t("tiedostot.modal.saveBtn")}
                      </button>
                      <button
                        className="btn btn-default"
                        id="submitFile"
                        style={{
                          marginTop: "0",
                          backgroundColor: "#F08262",
                          paddingTop: "0.25rem",
                          fontSize: "1.1rem",
                        }}
                        onClick={() => setCvModal(false)}
                      >
                        {t("tiedostot.modal.cancelBtn")}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>

            <div>
              <div id="fileDiv" style={{ marginTop: "3rem" }}>
                <table id="fileTb" className="content-table">
                  <thead className="">
                    <tr>
                      <th id="fileTh" colSpan="2">
                        {t("tiedostot.table.title")}
                      </th>
                    </tr>
                  </thead>
                  <tbody id="tBody">
                    <tr id="cv" className="subHead">
                      <th className="subTh" id="ansLuet" colSpan="2">
                        {t("tiedostot.table.ansioluettelot")}
                      </th>
                    </tr>
                    {userFiles.cv.map((file, i) => (
                      <tr key={i} className="fileTr">
                        <td className="fileTd">{file.name}</td>
                        <button
                          className="deleteFileBtn"
                          onClick={() => deleteFile(file, i)}
                          disabled={isLoading(i)}
                        >
                          {isLoading(i) ? (
                            <>
                              <LoadingSpinner type="verySmall" />
                            </>
                          ) : (
                            <>{t("tiedostot.table.poista")}</>
                          )}
                        </button>
                      </tr>
                    ))}
                    <tr id="tyo" className="subHead">
                      <th className="subTh" id="tyoHak" colSpan="2">
                        {t("tiedostot.table.tyohakemukset")}
                      </th>
                    </tr>
                    {userFiles.application.map((file, i) => (
                      <tr key={i} className="fileTr">
                        <td className="fileTd">{file.name}</td>
                        <button
                          className="deleteFileBtn"
                          onClick={() => deleteFile(file, i)}
                          disabled={isLoading(i)}
                        >
                          {isLoading(i) ? (
                            <>
                              <LoadingSpinner type="verySmall" />
                            </>
                          ) : (
                            <>{t("tiedostot.table.poista")}</>
                          )}
                        </button>
                      </tr>
                    ))}
                    <tr id="other" className="subHead">
                      <th className="subTh" id="muut" colSpan="2">
                        {t("tiedostot.table.muutTiedostot")}
                      </th>
                    </tr>
                    {userFiles.other.map((file, i) => (
                      <tr key={i} className="fileTr">
                        <td className="fileTd">{file.name}</td>
                        <button
                          className="deleteFileBtn"
                          onClick={() => deleteFile(file, i)}
                          disabled={isLoading(i)}
                        >
                          {isLoading(i) ? (
                            <>
                              <LoadingSpinner type="verySmall" />
                            </>
                          ) : (
                            <>{t("tiedostot.table.poista")}</>
                          )}
                        </button>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
}

export default Tiedostot;

/*
  
*/
