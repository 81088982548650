import { useState, react,useEffect,useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import LoadingSpinner from "../Misc/LoadingSpinner";



function DocsEmbed({ show, setOmatTiedotShow, elementId }) {
  const [showModal, setShowModal] = useState(true);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(true);

  function scrolltoId(){
    
    let element = document.getElementById(elementId);
    console.log(element)
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
    }
  
    async function fetchDocx() {
      setLoading(true)
      const response = fetch(
        "/api/files/docs/" + sessionStorage.getItem("id") + "/",
        {
          method: "get"
        }
      )
        .then(function (response) {
          if (!response.ok) throw new Error(response.status);
          else return response.text();
        })
        .then(function (data) {
          console.log("Data")
          setFile(data);
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
          console.log('error: ' + error);
        });
    }
  

    /*
    <button onClick={fetchDocx}>tesdsadst</button>
    <button onClick={scrolltoId}>scrolli</button>
    */
    
    useEffect(() => {
      fetchDocx()
    },[show]);

    useEffect(() => {
      scrolltoId()
    },[file, loading]);

    if(!loading)
    {
      return (
   
        <div className="popup-box" style={{color: 'black'}}>
            <div className="box">
            <span className="close-icon" onClick={() => setOmatTiedotShow(false)}>x</span>
            <div style={{height: '100%', textAlign: '-webkit-center'}} className="popup-box-text">
      
    <div dangerouslySetInnerHTML={{ __html: file }} />

            </div>
           
            </div>
            </div >

  
);


    }

    else{
      return(
    
    <div className="popup-box" style={{color: 'black'}} >
      
    
      <div className="box">
      <span className="close-icon" onClick={() => setOmatTiedotShow(false)}>x</span>
      <div style={{height: '100%', textAlign: 'center', marginTop:'15vh'}}>
    
      <LoadingSpinner type={"doc"}/>    
    
      </div>
     
      </div>
      </div >
      )
    
    }
 
}

export default DocsEmbed;
