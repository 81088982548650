import React, { useState, useEffect } from "react";
import {
  TableBody,
  TableCell,
  TableHead,
  Typography,
  Table,
  TableRow
} from "@mui/material";
import { Collapse } from "react-bootstrap";
import { Tooltip } from "react-tippy";
import { Box } from "@mui/material";
import { CaretDownFill, CaretRightFill } from "react-bootstrap-icons";

function SingleAssessment({ assessment }) {
  const [open, setOpen] = useState(false);


 



  return (
    <>
      <Box
        className="single-assessment"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          backgroundColor: open ? "lightgray" : "white",
          borderRadius: "8px 8px 0px 0px",
          marginTop: "5px",
        }}
        onClick={(e) => setOpen(!open)}
      >
        {open ? (
          <>
            <strong>
              <p style={{ marginBottom: "0px" }}>
                {new Date(assessment.PVM).toLocaleDateString("fi")}
              </p>
            </strong>
          </>
        ) : (
          <>
            <p style={{ marginBottom: "0px" }}>
              {new Date(assessment.PVM).toLocaleDateString("fi")}
            </p>
          </>
        )}

        {open ? (
          <>
            <CaretDownFill />
          </>
        ) : (
          <>
            <CaretRightFill />
          </>
        )}
      </Box>

      <Collapse in={open}>
        <Box
          sx={{
            backgroundColor: "whitesmoke",
            padding: "5px",
            borderRadius: "0px 0px 8px 8px",
          }}
        >
          <Box>
            <Typography>
              <strong>Moduuli:</strong> {assessment.module}
            </Typography>
          </Box>
          <Box>
            <Table>
              <TableBody>
                <TableCell>
                  <strong>Puhuminen:</strong>
                </TableCell>
                <TableCell>{assessment.speech}</TableCell>
                <TableCell>
                  <strong>Kuullun ymmärtäminen:</strong>{" "}
                </TableCell>
                <TableCell>{assessment.speechUnderstanding}</TableCell>
              </TableBody>
            </Table>
            <Box>
              <Table>
                <TableBody>
                  <TableCell>
                    <strong>Kirjoittaminen:</strong>
                  </TableCell>
                  <TableCell>{assessment.writing}</TableCell>
                  <TableCell>
                    <strong>Luetun ymmärtäminen:</strong>{" "}
                  </TableCell>
                  <TableCell>{assessment.writingUnderstanding}</TableCell>
                </TableBody>
              </Table>
            </Box>
          </Box>
          <Box>
            <Typography sx={{ marginTop: "15px" }}>
              <strong>Yleinen:</strong> {assessment.markedAvg}
            </Typography>
            {assessment.additionalInfo && (
              <Typography sx={{ marginTop: "15px" }}>
                <strong>Lisätietoja:</strong>
                <br /> {assessment.additionalInfo}
              </Typography>
            )}
          </Box>
        </Box>
      </Collapse>
    </>
  );
}

export default SingleAssessment;
