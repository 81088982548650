import { React, useState, useEffect } from "react";

import Select from "react-select";
import { Spinner } from "react-bootstrap";
function CustomProjectView({ projects, getProjects, loading }) {
  //console.log(projects)
  const [project, setProject] = useState();
  const [refreshCustomProjects, setRefreshCustomProjects] = useState();
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "2vh",
    }),
  };

  const loadingStyle = {
    width: "5vh",
  };

  return (
    <>
      <Select
        onChange={(choice) => getProjects(choice)}
        options={projects}
        styles={customStyles}
        placeholder={"Valitse projekti"}
      />

      {loading ? (
        <>
          <div style={{ marginTop: "10px" }}>
            <Spinner animation="border" />
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default CustomProjectView;
