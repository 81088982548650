import React from "react";
import { Button, Table } from "react-bootstrap";
import { InfoCircleFill } from "react-bootstrap-icons";
import SingleCollapse from "../components/WhatsNew/SingleCollapse";
import { versionHistory } from '../components/WhatsNew/VersionHistory';
function WhatsNew() {


    /*TYHJÄ TEMPLATE---------
        <SingleCollapse
              header={""}
              text={
                ""
              }
              pvm={""}
              versio={}
        />
    -----------------------*/

  return (
    <>
    <div id="tableContainer">
      <h3 className="fbH3">Mitä uutta?</h3>

      <div>
        <Table
          className="whatsNewTable"
          style={{ marginTop: "2.5rem", width: "85%" }}
        >
          <thead></thead>
          <tbody>
                    {versionHistory.map((item) => (
                      <SingleCollapse
                      header={item.header}
                      text={item.text}
                      pvm={item.pvm}
                      versio={999}
                      />
                    ))}
                 
      
                  </tbody>
        </Table>
        </div>
      </div>
    </>
  );
}

export default WhatsNew;
