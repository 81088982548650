import React, { useState, useEffect, useCallback, useRef } from "react";
import { Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import "react-toastify/dist/ReactToastify.css";
import { Dropdown } from "react-bootstrap";
import { Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import SingleWebinarMonth from "../components/SingleWebinarMonth";
import { useNavigate } from "react-router-dom";

const loadingStyle = {
  textAlign: "center",
  color: "white",
  marginTop: "15vh",
  width: "100vw",
  height: "65vh",
};

function Home({
  setInsModal,
  loading,
  handleCallbackResponse,
  login,
  handleFirstNavigation,
  setHandleFirstNavigation,
  webinars
}) {
  const [animationClass, setAnimationClass] = useState(["", "", ""]);
  const { t } = useTranslation();
  const boxRefs = [useRef(null), useRef(null), useRef(null)];
  const location = useLocation();
  const navigate = useNavigate();

  const handleOnClick = useCallback(
    () => navigate("/palvelusi", { replace: true }),
    [navigate]
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");
    if (code) handleCallbackResponse(code);
  }, [location]);

  useEffect(() => {
    if(handleFirstNavigation === "navigate") {
      handleOnClick()
      setHandleFirstNavigation(null)
    }
  }, [handleFirstNavigation]);

  useEffect(() => {
    if (sessionStorage.getItem("log") !== "true") {
      sessionStorage.setItem("log", "true");
      let apiCallTime = localStorage.getItem("projectsTimestamp");
      let currentTime = new Date().getTime();
      let timeDifference = currentTime - apiCallTime;
      var difference = timeDifference / (1000 * 60 * 60);
      let date = new Date().getTime();

      if (!localStorage.getItem("projectsTimestamp") || difference >= 2) {
        fetch("/api/logs/entry", {
          method: "POST", // or 'PUT'
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then(function (response) {
            if (!response.ok) throw new Error(response.status);
            //if (response === "nopermission")
            return response;
          })
          .then((data) => {
            localStorage.setItem("projectsTimestamp", date);
          });
      }
    }
  });

  useEffect(() => {
    const debouncedHandleScroll = debounce(handleScroll, 10);
    window.addEventListener("scroll", debouncedHandleScroll);
    return () => window.removeEventListener("scroll", debouncedHandleScroll);
  });

  function debounce(fn, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => fn(...args), wait);
    };
  }

  function handleScroll() {
    const currentScrollY = window.scrollY;
    boxRefs.forEach((boxRef, index) => {
      const boxTop = boxRef?.current?.offsetTop;
      const isGoingUp = currentScrollY > boxTop - 250;
      let newAnimations = ["", "", ""];
      if (isGoingUp) {
        newAnimations[index] =
          animationClass[index] === "float-up" ? "float-down" : "float-up";
        setAnimationClass(newAnimations);
      }
    });
  }

  if (loading) {
    return (
      <div id="tableContainerFront" style={loadingStyle}>
        <Spinner style={{ width: "7vh", height: "7vh" }} animation="border" />
      </div>
    );
  }

  return (
    <div id="indexContainer" style={{ paddingBottom: "50px" }}>
      <div id="parasPolkuCont">
        <div id="leftPolku">
          <div id="headerDiv">
            <h2 className="polkuHeader">{t("home.header.Paras")}</h2>
            <h3 className="polkuHeader">{t("home.header.Polku")}</h3>
            <h2 className="polkuHeader">{t("home.header.Työhön")}</h2>
          </div>
        </div>
        <div id="rightPolku">
          {sessionStorage.getItem("user") ? (
            <></>
          ) : (
            <>
              <Paper elevation={3} className="polkuTextDiv">
                <Typography variant="h4">
                  {t("home.tervetuloa.title")}
                </Typography>
                {t("home.tervetuloa.p1")}
                <Typography>{t("home.tervetuloa.loginA")}</Typography>
                {t("home.tervetuloa.p2")}
                <button
                  className="link-button"
                  onClick={() => setInsModal(true)}
                >
                  {t("home.tervetuloa.instructA")}
                </button>
                {t("home.tervetuloa.p3")} <br />
                <br /> {t("home.tervetuloa.p4")}
              </Paper>
            </>
          )}
        </div>
      </div>

      <div className="floatContainer" ref={boxRefs[0]}>
        <Paper id="textBox" className={`floating-box ${animationClass[0]}`}>
          <h2>{t("home.webinaarit.title")}</h2>
          <p>
            {t("home.webinaarit.p1")}
            <br />
            <br />
            {t("home.webinaarit.p2")}
            <br />
            <br />
            {t("home.webinaarit.p3")}
          </p>

          {webinars ? (
            <div>
              {Object.keys(webinars).map((month) => (
                <SingleWebinarMonth
                  month={month}
                  webinars={webinars}
                  prop={"etusivu"}
                />
              ))}
            </div>
          ) : (
            <></>
          )}

          {/* <Button
            id="floatBtn"
            onClick={() =>
              window.open("https://www.arffman.fi/polku/webinaarit/", "_blank")
            }
          >
            {t("home.webinaarit.p5")}
          </Button> */}
        </Paper>

        <div
          id="imgBox"
          className={
            animationClass[0] === "float-up"
              ? `floating-box float-down`
              : `floating-box float-up`
          }
        >
          <img
          alt="element3"
            style={{ width: "100%" }}
            src="https://www.arffman.fi/wp-content/uploads/2021/09/polku-page-elements-3-500x375.png"
          />
        </div>
      </div>

      <div className="floatContainer" ref={boxRefs[1]}>
        <Paper id="textBox" className={`floating-box ${animationClass[1]}`}>
          <h2>{t("home.verkkomateriaali.title")}</h2>
          <p>
            {t("home.verkkomateriaali.p1")}
            <br />
            <br />
            {t("home.verkkomateriaali.p2")}
            <br />
            <br />
          </p>
        </Paper>

        <div
          id="imgBox"
          className={
            animationClass[1] === "float-up"
              ? `floating-box float-down`
              : `floating-box float-up`
          }
        >
          <img
          alt="element4"
            style={{ width: "100%" }}
            src="https://www.arffman.fi/wp-content/uploads/2021/09/polku-page-elements-1-3-500x375.png"
          />
        </div>
      </div>

      <div className="floatContainer" ref={boxRefs[2]}>
        <Paper id="textBox" className={`floating-box ${animationClass[2]}`}>
          <h2>{t("home.youtube.title")}</h2>
          <p>
            {t("home.youtube.p1")}
            <br />
            <br />
            {t("home.youtube.p2")}
            <br />
            <br />
            {t("home.youtube.p3")}
          </p>
          <Button
            id="floatBtn"
            onClick={() =>
              window.open(
                "https://www.arffman.fi/polku/paras-polku-tyohon-youtube-live/",
                "_blank"
              )
            }
          >
            {t("home.youtube.p5")}
          </Button>
        </Paper>

        <div
          id="imgBox"
          className={
            animationClass[2] === "float-up"
              ? `floating-box float-down`
              : `floating-box float-up`
          }
        >
          <img
          alt="element5"
            style={{ width: "100%" }}
            src="https://www.arffman.fi/wp-content/uploads/2021/09/polku-page-elements-2-500x375.png"
          />
        </div>
      </div>

      <div className="cardContainer">
        <div className="polkuCard">
          <img
             alt="element6"
            className="cardImg"
            src="https://www.arffman.fi/wp-content/uploads/2021/09/7.jpg"
          />
          <h2>{t("home.foreammatit.title")}</h2>
          <p>
            {t("home.foreammatit.p1")} <br />
            <br />
            {t("home.foreammatit.p2")}
          </p>
          <Button
            onClick={() => window.open("https://www.foreammatti.fi/", "_blank")}
            id="cardButton"
          >
            {t("home.foreammatit.p5")}
          </Button>
        </div>

        <div className="polkuCard">
          <img
             alt="element7"
            className="cardImg"
            src="https://www.arffman.fi/wp-content/uploads/2021/09/polku-page-elements-.jpg"
          />
          <h2>{t("home.vainu.title")}</h2>
          <p>
            {t("home.vainu.p1")} <br />
            <br />
            {t("home.vainu.p2")}
          </p>
          <Button
            onClick={() => window.open("https://vainu.io/", "_blank")}
            id="cardButton"
          >
            {t("home.vainu.p5")}
          </Button>
        </div>

        <div className="polkuCard">
          <img
             alt="element8"
            className="cardImg"
            src="https://www.arffman.fi/wp-content/uploads/2021/09/6.jpg"
          />
          <h2>
            {t("home.verkkovalmentaja.title1")}
            <br /> {t("home.verkkovalmentaja.title2")}
          </h2>
          <p style={{ minHeight: "14.25rem" }}>
            {t("home.verkkovalmentaja.p1")} <br />
            <br />
            {t("home.verkkovalmentaja.p2")}
          </p>

          <Dropdown>
            <Dropdown.Toggle id="customDropDown">
              <div style={{ alignContent: "center" }} id="cardButton">
                {t("home.verkkovalmentaja.p5")}
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ width: "100%", textAlign: "center" }}>
              <Dropdown.Item
                style={{ cursor: "pointer" }}
                target="_blank"
                href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ2tO0xURjXX1Hz6uUd1_QE2CavbGSyGau2b6czCxJsYdVoWkaB5742dKo8ckoSLPKXxzZB7mF-"
              >
                Tiina Saarinen
              </Dropdown.Item>
              <Dropdown.Item
                style={{ cursor: "pointer" }}
                target="_blank"
                href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ2IAMjDGVrpUrHOi4VyYej6QsxsRZsjeSK_4ahqTaxAhWB0Twc5JWlJjWs1mev0rIFDBh3COtX-
                "
              >
                Antti Rautio
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <div className="divideCont">
        <div className="divideChild">
          <img
             alt="element10"
            className="iconImg"
            src="https://www.arffman.fi/wp-content/uploads/2023/01/polu-icons-update-1.png"
          />
          <h2>{t("home.psykologi.title")}</h2>
          <p>
            {t("home.psykologi.p1")} <br />
            <br />
            {t("home.psykologi.p2")}
          </p>
          <hr className="divideHr" />
          <img
             alt="element11"
            className="iconImg"
            src="https://www.arffman.fi/wp-content/uploads/2023/01/uraohjaaja-polu-icons-update.png"
          />
          <h2>{t("home.uraohjaaja.title")}</h2>
          <p>
            {t("home.uraohjaaja.p1")} <br />
            <br />
            {t("home.uraohjaaja.p2")}
          </p>
        </div>
        <div className="divideChild">
          <img
             alt="element12"
            className="iconImg"
            src="https://www.arffman.fi/wp-content/uploads/2023/01/tyonetsija-polu-icons-update.png"
          />
          <h2>{t("home.työnetsijä.title")}</h2>
          <p>
            {t("home.työnetsijä.p1")} <br />
            <br />
            {t("home.työnetsijä.p2")}
          </p>
          <hr className="divideHr" />
          <img
             alt="element13"
            className="iconImg"
            src="https://www.arffman.fi/wp-content/uploads/2023/01/yrittajyysvalmentaja-polu-icons-update.png"
          />
          <h2>{t("home.yrittäjyysvalmentaja.title")}</h2>
          <p>
            {t("home.yrittäjyysvalmentaja.p1")} <br />
            <br />
            {t("home.yrittäjyysvalmentaja.p2")}
          </p>
        </div>
      </div>
      <div className="freeTextDiv">
        <div style={{ textAlign: "center" }}>
          <a href="https://free.fi/" rel="noreferrer" target="_blank">
            <img
               alt="element14"
              style={{ width: "8rem" }}
              src="https://free.fi/wp-content/themes/FREEfi/assets/free-fi_logo.svg"
            ></img>
          </a>
        </div>
        <h2 style={{ textAlign: "center", marginTop: "2rem" }}>
          {t("home.free2.title")}
        </h2>

        <p className="polkuText">
          <a className="floatAnchor" rel="noreferrer" href="https://free.fi/" target="_blank">
            {t("home.free2.p1a1")}
          </a>
          {t("home.free2.p1")}
        </p>
      </div>

      <div style={{ marginTop: "5%" }}></div>
    </div>
  );
}

export default Home;
