import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  Divider,
  CircularProgress,
  IconButton,
} from "@mui/material";
import Container from "@mui/material/Container";
import { Paper, Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CustomProjectView from "../components/CustomProjects";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../components/Misc/LoadingSpinner";
import Links from "./Links";
import Collapse from "react-bootstrap/Collapse";
import ProgressBar from "react-bootstrap/ProgressBar";
import LinearProgress from "@mui/material/LinearProgress";
import { Tooltip as TippyTooltip } from "react-tippy";
import { PDFViewer } from "@react-pdf/renderer";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  LineChart,
  Line,
} from "recharts";
import {
  TableBody,
  TableCell,
  TableHead,
  Table,
  TableRow,
  Card,
  CardContent
} from "@mui/material";
import SingleWebinarMonth from "../components/SingleWebinarMonth";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";

import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { CaretRightFill } from "react-bootstrap-icons";
import SingleAssessment from "../components/Misc/SingleAssessment";
import ParticipationCertificate from "../components/ParticipationCertificate";
import MeetingsTable from "../components/MeetingsTable";
const ServiceInformationGrid = ({
  showAllProjects,
  allProjects,
  getCustomProject,
  userData,
  customLoading,
  showProjectSelection,
  setShowProjectSelection,
  projectLinks,
  guessedEndDate,
  progress,
  webinars,
  assessments,
}) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [showReturnModal, setShowReturnModal] = useState(false);
  const [showReturnBox, setShowReturnBox] = useState(false);
  const [returnCode, setReturnCode] = useState("");
  const [barData, setBarData] = useState([]);
  const [chartHeight, setChartHeight] = useState(100);
  const [barModal, setBarModal] = useState(false);
  const [lineData, setLineData] = useState([]);
  const [open, setOpen] = useState({});
  const [meetings, setMeetings] = useState([]);
  const [showParticipationCertificate, setShowParticipationCertificate] =
    useState(false);
  const [openMeetings, setOpenMeetings] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [upcomingMeetings, setUpcomingMeetings] = useState();

  const handleOpenMeetingsModal = () => setOpenMeetings(true);


  const proficiencyLevels = [
    "0",
    "A1.1",
    "A1.2",
    "A2.1",
    "A2.2",
    "B1.1",
    "B1.2",
    "B2.1",
    "B2.2",
  ];

  const CustomizedAxisTick = ({ x, y, payload }) => {
    return (
      <text x={x} y={y} fill="#666" textAnchor="middle" dy={16}>
        {payload.value}
      </text>
    );
  };
  const formatDate = (dateTime) => {
    return new Date(dateTime).toLocaleString("fi-FI", {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
  };
  const handleBarOpen = (id) => {
    setBarModal(true);
  };

  const notifyError = (msg) =>
    toast.error(msg, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifySuccess = (msg) =>
    toast.success(msg, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const handleReturnCodeSubmit = () => {
    let obj = { returnCode };

    const response = fetch(
      "/api/user/returnCodeSubmit/" + sessionStorage.getItem("id"),
      {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        //console.log(data);
        if (data.status === "SUCCESS") {
          sessionStorage.setItem("returnCode", returnCode);
          setReturnCode("");
          setShowReturnBox(false);
          setShowReturnModal(false);
          notifySuccess("Seurantatunnus tallennettu");
        } else {
          notifyError(
            "Jokin meni vikaan. Yritä uudelleen ja ota tarvittaessa yhteys sovelluskehitys@edu.arffman.fi"
          );
        }
      });
  };

  const barValueConvert = {
    0: 0,
    "A1.1": 1,
    "A1.2": 2,
    "A1.3": 3,
    "A2.1": 4,
    "A2.2": 5,
    "B1.1": 6,
    "B1.2": 7,
    "B2.1": 8,
    "B2.2": 9,
    "C1.1": 10,
    "C1.2": 11,
    "C2.1": 12,
    "C2.2": 13,
  };

  const numericValueToString = (value) => {
    const mapping = {
      0: "0",
      1: "A1.1",
      2: "A1.2",
      3: "A1.3",
      4: "A2.1",
      5: "A2.2",
      6: "B1.1",
      7: "B1.2",
      8: "B2.1",
      9: "B2.2",
      10: "C1.1",
      11: "C1.2",
      12: "C2.1",
      13: "C2.2",
    }; // Extend this mapping as needed
    return mapping[value] || value;
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, value } = payload[0].payload; // Accessing the original payload data
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#ffff",
            color: "black",
            padding: "5px",
            border: "1px solid #cccccc",
          }}
        >
          <p>{name}</p>
          <p>{`${t("kielitaitoarviot.arviointi")}: ${numericValueToString(
            value
          )}`}</p>
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    if (assessments && assessments.length > 0) {
      //console.log("assetit:", assessments);

      let personLanguageLevelGoal = JSON.parse(
        sessionStorage.getItem("userData")
      ).languageLevelGoal.value;
      let personDontShowLevelGoal = JSON.parse(
        sessionStorage.getItem("userData")
      ).dontShowLanguageLevelGoal.value;

      let projectLanguageLevelGoal = JSON.parse(
        sessionStorage.getItem("userData")
      ).projectLanguageLevelGoal.value;
      let projectDontShowLevelGoal = JSON.parse(
        sessionStorage.getItem("userData")
      ).projectDontShowLanguageLevelGoal.value;

      let languageLevelGoal = "B1.1";

      if (personLanguageLevelGoal && !personDontShowLevelGoal) {
        languageLevelGoal = personLanguageLevelGoal;
      } else if (projectLanguageLevelGoal && !projectDontShowLevelGoal) {
        languageLevelGoal = projectLanguageLevelGoal;
      }

      let data = [];

      //console.log("languageLevelGoal:", languageLevelGoal);

      let dataForBar = [];

      if (!projectDontShowLevelGoal && !personDontShowLevelGoal) {
        dataForBar.push({
          name: t("kielitaitoarviot.tavoite"),
          value: languageLevelGoal,
          numericValue: 6,
          id: "goal",
        });

        data.push({
          name: t("kielitaitoarviot.tavoite"),
          markedAvg: barValueConvert[languageLevelGoal],
          speech: barValueConvert[languageLevelGoal],
          speechUnderstanding: barValueConvert[languageLevelGoal],
          writing: barValueConvert[languageLevelGoal],
          writingUnderstanding: barValueConvert[languageLevelGoal],
          id: "goal",
        });
      }

      let openAssessments = {};

      for (let i = 0; i < assessments.length; i++) {
        let single = assessments[i];
        dataForBar.push({
          name: new Date(single.PVM).toLocaleDateString("fi"),
          value: single.markedAvg,
          numericValue: barValueConvert[single.markedAvg],
          id: single.id,
        });

        data.push({
          name: new Date(single.PVM).toLocaleDateString("fi"),
          markedAvg: barValueConvert[single.markedAvg],
          speech: barValueConvert[single.speech],
          speechUnderstanding: barValueConvert[single.speechUnderstanding],
          writing: barValueConvert[single.writing],
          writingUnderstanding: barValueConvert[single.writingUnderstanding],
          id: single.id,
        });

        openAssessments[assessments[i].id] = false;
      }

      //console.log("datat:", openAssessments);

      setOpen(openAssessments);

      const baseHeightPerBar = 50;
      setChartHeight(data.length * baseHeightPerBar);
      setBarData(dataForBar);

      //console.log(data.slice().reverse());
      setLineData(data.slice().reverse());
    }
  }, [assessments]);

  useEffect(() => {
    // Assuming you are fetching data or waiting for something to happen before showing content.
    // If it is user data, ensure it is available and has been fetched.
    if (userData) {
      setLoading(false);
    }
  }, [userData]);
  //  {sessionStorage.getItem("testUser") === "true" ? (

  /* 
   <Grid item xs={12}>
        {showProjectSelection ? (
          <>
            {loading ? (
              <>
                <LoadingSpinner type="verySmall" />
              </>
            ) : (
              <>
                {allProjects ? (
                  <>
                    <div>
                      <CustomProjectView
                        projects={allProjects}
                        getProjects={getCustomProject}
                        loading={customLoading}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <Button
              sx={{ textAlign: "center" }}
              onClick={() => showAllProjects()}
              variant="contained"
            >
              Vaihda projektia
            </Button>
          </>
        )}
      </Grid>
  */
  useEffect(() => {
    fetchMeetings();
  }, []);

  async function fetchMeetings() {
    try {
      const response = await fetch("/api/user/getUserMeetings/" + sessionStorage.getItem("id") + "/", {
        method: "POST",
      });

      if (response.ok) {
        const data = await response.json();
        const meetingsArr = data.response;

        // Filter meetings that are in the future
        const upcoming = meetingsArr.filter(meeting => new Date(meeting.meetingDate) > new Date());

        // Sort meetings by date to find the next meeting
        upcoming.sort((a, b) => new Date(a.meetingDate) - new Date(b.meetingDate));

        // Set only the next meeting
        const nextMeeting = upcoming.length > 0 ? [upcoming[0]] : [];
        setUpcomingMeetings(nextMeeting);
        setMeetings(meetingsArr);

        // Check if the next meeting is today and show a notification
        if (nextMeeting.length > 0 && isToday(new Date(nextMeeting[0].meetingDate))) {
          notifySuccess(t("meetings.meetingsAlert"));
        }
      }
    } catch (error) {
      console.error("Virhe tapaamisten hakemisessa:", error);
    }
  }

  // Helper function to check if a date is today
  function isToday(date) {
    const today = new Date();
    return date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
  }

  const content = (
    <>
      {
        <>
          <Grid item xs={12}>
            {userData && userData.projectName.value ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "right",
                      fontWeight: "bold",
                      fontSize:
                        userData.projectName.length > 25 ? "1rem" : "1.20rem",
                    }}
                    variant="h5"
                  >
                    {userData.projectName.value}
                  </Typography>

                  {sessionStorage.getItem("testUser") === "true" && (
                    <IconButton
                      onClick={() => showAllProjects()}
                      sx={{ color: "black", backgroundColor: "white" }}
                    >
                      {" "}
                      <FormatListBulletedIcon />
                    </IconButton>
                  )}
                </Box>
                {sessionStorage.getItem("testUser") === "true" && (
                  <Typography>
                    <strong>
                      Huom! Olet kirjautunut henkilöstön testikäyttäjänä.
                    </strong>
                  </Typography>
                )}
                {sessionStorage.getItem("testUser") === "true" ? (
                  <>
                    {showProjectSelection ? (
                      <>
                        {loading ? (
                          <>
                            <LoadingSpinner type="verySmall" />
                          </>
                        ) : (
                          <>
                            {allProjects ? (
                              <>
                                <div>
                                  <CustomProjectView
                                    projects={allProjects}
                                    getProjects={getCustomProject}
                                    loading={customLoading}
                                  />
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>-</>
            )}
          </Grid>

          <Grid sx={{ padding: "10px" }} item xs={12}>
            <Divider variant="middle" sx={{ bgcolor: "secondary.light" }} />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">{t("palvelusi.kouluttaja")}</Typography>
          </Grid>

          <Grid item xs={12}>
            {" "}
            {userData && userData.mainTeacher ? (
              <>
                <Typography
                  sx={{
                    textAlign: "right",
                    fontWeight: "bold",
                  }}
                  variant="body1"
                >
                  {userData.mainTeacher.name}
                </Typography>
                <Typography
                  sx={{
                    textAlign: "right",
                    marginBottom: "2px",
                  }}
                  variant="body1"
                >
                  <a href={"mailto:" + userData.mainTeacher.email}>
                    {userData.mainTeacher.email}
                  </a>
                </Typography>
                {userData.mainTeacher.phone ? (
                  <>
                    <br></br>
                    <Typography
                      sx={{
                        textAlign: "right",
                        marginBottom: "2px",
                      }}
                      variant="body1"
                    >
                      <a href={"tel:" + userData.mainTeacher.phone}>
                        {userData.mainTeacher.phone}
                      </a>
                    </Typography>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : userData && !userData.mainTeacher && userData.instructor ? (
              <>
                <Typography
                  sx={{
                    textAlign: "right",
                    fontWeight: "bold",
                  }}
                  variant="body1"
                >
                  {userData.instructor.value.name}
                </Typography>
                <Typography
                  sx={{
                    textAlign: "right",
                    marginBottom: "2px",
                  }}
                  variant="body1"
                >
                  <a href={"mailto:" + userData.instructor.value.email}>
                    {userData.instructor.value.email}
                  </a>
                </Typography>
                {userData.instructor.value.phone ? (
                  <>
                    <Typography
                      sx={{
                        textAlign: "right",
                        marginBottom: "2px",
                      }}
                      variant="body1"
                    >
                      <a href={"tel:" + userData.instructor.value.phone}>
                        {userData.instructor.value.phone}
                      </a>
                    </Typography>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>-</>
            )}
          </Grid>

          <Divider sx={{ margin: "20px", bgcolor: "secondary.light" }} variant="middle"></Divider>
          {sessionStorage.getItem("testUser") === "true" && (
            <Grid item xs={12}>
              <Button
                onClick={() => setShowParticipationCertificate(true)}
                color="secondary"
                variant="contained"
              >
                Osallistumistodistus
              </Button>
            </Grid>
          )}
          {showParticipationCertificate && (
            <Modal
              size="xl"
              className="insModal"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={showParticipationCertificate}
              onHide={() => setShowParticipationCertificate(false)}
            >
              <Modal.Header style={{ alignSelf: "center" }}>
                <Modal.Title>Osallistumistodistus</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ height: "70dvh", textAlign: "center" }}>
                <PDFViewer style={{ height: "100%", width: "50%" }}>
                  <ParticipationCertificate
                    city={userData?.projectCity?.value}
                    expert={userData?.mainTeacher?.name}
                    service={userData?.projectName?.value}
                    start={userData?.projectStartDate?.value}
                    end={userData?.projectEndDate?.value}
                  />
                </PDFViewer>
              </Modal.Body>
            </Modal>
          )}

          <Divider variant="middle" sx={{ bgcolor: "secondary.light", margin: "20px" }} />

          <Grid style={{ margin: 20 }}>
            <Typography variant="h5" fontWeight="bold" component="h1" gutterBottom>
              {t("meetings.title1")}
            </Typography>

            {upcomingMeetings && upcomingMeetings.length > 0 ? (
              <Box style={{ marginTop: 20 }}>
                <Typography variant="h6" component="div">
                  {upcomingMeetings[0].meetingTitle}
                </Typography>
                <Typography color="textSecondary">
                  {upcomingMeetings[0].serviceType}
                </Typography>
                <Typography color="textSecondary">
                  {formatDate(new Date(upcomingMeetings[0].meetingDate))}
                </Typography>
              </Box>
            ) : (
              <Typography>{t("meetings.noUpcomingMeetings")}</Typography>
            )}
          </Grid>
          <Button color="secondary"
            variant="contained"  onClick={handleOpenMeetingsModal}>
            {t("meetings.button1")}
          </Button>
          <MeetingsTable
            meetings={meetings} show={openMeetings} handleCloseMeetings={() => setOpenMeetings(false)}
          />
          <Divider variant="middle" sx={{ marginTop: "20px", bgcolor: "secondary.light" }}></Divider>
          <Grid sx={{ padding: "20px" }} item xs={12}>
          </Grid>
          <Grid
            container
            spacing={3}
            sx={{
              paddingLeft: "2vw!important",
              paddingRight: "2vw!important",
            }}
          >
            {projectLinks && projectLinks.length > 0 ? (
              <>
                {projectLinks.map((link) => (
                  <Grid key={link?.name} item xs={6} md={4} lg={4}>
                    <Links link={link} />
                  </Grid>
                ))}
              </>
            ) : (
              <></>
            )}
          </Grid>
          <Grid sx={{ padding: "20px" }} item xs={12}>
            <Divider variant="middle" sx={{ bgcolor: "secondary.light" }} />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              paddingLeft: "1vw!important",
              paddingRight: "1vw!important",
            }}
          >
            {" "}
            {userData && userData.startDate ? (
              <>
                <div style={{ display: "inline-block", width: "100%" }}>
                  <div style={{ float: "left" }}>
                    {guessedEndDate ? (
                      <>
                        <TippyTooltip
                          theme="custom1"
                          size="small"
                          title={guessedEndDate.startTitle}
                          position="bottom"
                          trigger="mouseenter"
                        >
                          {guessedEndDate.startDate}
                        </TippyTooltip>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div style={{ float: "right" }}>
                    {guessedEndDate ? <>{guessedEndDate.endDate}</> : <></>}
                  </div>
                </div>

                {progress ? (
                  guessedEndDate ? (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box sx={{ width: "100%", mr: 1 }}>
                        <LinearProgress
                          variant="determinate"
                          value={progress}
                        />
                      </Box>
                      <Box sx={{ minWidth: 35 }}>
                        <Typography variant="subtitle1">{`${Math.round(
                          progress
                        )}%`}</Typography>
                      </Box>
                    </Box>
                  ) : (
                    <ProgressBar now={progress} label={`${progress}%`} />
                  )
                ) : (
                  <>
                    <TippyTooltip
                      theme="custom1"
                      size="small"
                      title="Palvelullesi ei ole vielä merkitty aloitus- tai päättymispäivämäärää"
                      position="bottom"
                      trigger="mouseenter"
                    >
                      <ProgressBar now={0} label={`${0}%`} />
                    </TippyTooltip>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </Grid>
          <Grid sx={{ padding: "20px" }} item xs={12}>
            <Divider variant="middle" sx={{ bgcolor: "secondary.light" }} />
          </Grid>
          {userData && userData.pcSerial.value && (
            <>
              <table
                className="content-table"
                style={{ width: "72%", marginTop: "2%" }}
              >
                <tbody>
                  <tr>
                    <td id="pointer">{t("palvelusi.palautusohjeet.p15")}</td>
                    <td></td>
                    <td>{userData.pcSerial.value}</td>
                  </tr>
                  {sessionStorage.getItem("returnCode") ||
                    userData.returnCode.value ? (
                    <tr>
                      <td id="pointer">{t("palvelusi.palautusohjeet.p16")}</td>
                      <td></td>
                      <td>
                        <a
                          href={`https://www.posti.fi/fi/seuranta#/lahetys/${sessionStorage.getItem("returnCode")
                              ? sessionStorage.getItem("returnCode").toString()
                              : userData.returnCode.value.toString()
                            }`}
                          target="_blank"
                        >
                          {sessionStorage.getItem("returnCode")
                            ? sessionStorage.getItem("returnCode").toString()
                            : userData.returnCode.value.toString()}
                        </a>
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
              <div>
                <Button
                  onClick={() => setShowReturnModal(true)}
                  style={{
                    backgroundColor: "#7db5da",
                    border: "none",
                    color: "black",
                    marginTop: "1rem",
                  }}
                >
                  {t("palvelusi.palautusohjeet.title")}
                </Button>
              </div>
            </>
          )}
          <Grid sx={{ padding: "20px" }} item xs={12}>
            <Divider variant="middle" sx={{ bgcolor: "secondary.light" }} />
          </Grid>

          {assessments && (
            <>
              <Typography variant="h6">
                {t("kielitaitoarviot.title")}
              </Typography>
              <div style={{ display: "flex", placeContent: "center" }}>
                <BarChart
                  width={450}
                  height={chartHeight}
                  data={barData}
                  layout="vertical"
                  onClick={(e) => handleBarOpen()}
                  margin={{ top: 20, right: 30, left: 30, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" tickFormatter={numericValueToString} />
                  <YAxis dataKey="name" type="category" />
                  <Tooltip content={<CustomTooltip />} />
                  <Bar dataKey="numericValue" fill="#7db5da" barSize={20}>
                    <LabelList
                      dataKey="value"
                      style={{ fill: "black" }}
                      position="insideTop"
                    />
                  </Bar>
                </BarChart>
              </div>
            </>
          )}
        </>
      }
    </>
  );

  return (
    <>
      {assessments && (
        <Modal
          size="xl"
          className="insModal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={barModal}
          onHide={() => setBarModal(false)}
        >
          <Modal.Header style={{ alignSelf: "center" }}>
            <Modal.Title>{t("kielitaitoarviot.title")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table
              style={{
                tableLayout: "fixed",
                width: "100%", // Ensure the table width fits the page
              }}
              striped
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    align="right"
                    style={{
                      textAlign: "center",
                      border: "1px solid rgba(224, 224, 224, 1)",
                    }}
                  >
                    {t("kielitaitoarviot.pvm")}
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{
                      textAlign: "center",
                      border: "1px solid rgba(224, 224, 224, 1)",
                    }}
                  >
                    {t("kielitaitoarviot.moduuli")}
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{
                      textAlign: "center",
                      border: "1px solid rgba(224, 224, 224, 1)",
                    }}
                  >
                    {t("kielitaitoarviot.puhuminen")}
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{
                      border: "1px solid rgba(224, 224, 224, 1)",
                      textAlign: "center",
                    }}
                  >
                    {t("kielitaitoarviot.kuullun")}
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{
                      textAlign: "center",
                      border: "1px solid rgba(224, 224, 224, 1)",
                    }}
                  >
                    {t("kielitaitoarviot.kirjoittaminen")}
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{
                      border: "1px solid rgba(224, 224, 224, 1)",
                      textAlign: "center",
                    }}
                  >
                    {t("kielitaitoarviot.luetun")}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      border: "1px solid rgba(224, 224, 224, 1)",
                      textAlign: "center",
                    }}
                  >
                    {t("kielitaitoarviot.yleinen")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {assessments.map((assessment, index) => (
                  <React.Fragment key={assessment.id}>
                    <TableRow
                      className="assessmentRow"
                      style={{
                        backgroundColor:
                          index % 2 === 0
                            ? "transparent"
                            : "rgba(219, 219, 219, 0.568)",
                      }}
                    >
                      <TableCell
                        align="center"
                        style={{
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "3px",
                          textAlign: "center",
                        }}
                      >
                        {" "}
                        {new Date(assessment.PVM).toLocaleDateString("fi")}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "3px",
                          textAlign: "center",
                        }}
                      >
                        {assessment.module}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "3px",
                          textAlign: "center",
                        }}
                      >
                        {assessment.speech}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "3px",
                          textAlign: "center",
                        }}
                      >
                        {assessment.speechUnderstanding}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "3px",
                          textAlign: "center",
                        }}
                      >
                        {assessment.writing}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "3px",
                          textAlign: "center",
                        }}
                      >
                        {assessment.writingUnderstanding}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "3px",
                          textAlign: "center",
                        }}
                      >
                        {assessment.markedAvg}
                      </TableCell>
                    </TableRow>
                    {assessment.additionalInfo && (
                      <>
                        <TableRow
                          style={{
                            backgroundColor:
                              index % 2 === 0
                                ? "transparent"
                                : "rgba(219, 219, 219, 0.568)",
                            borderRight: "thin solid lightgray",
                            borderLeft: "thin solid lightgray",
                          }}
                        >
                          <TableCell
                            style={{
                              padding: "2px 65px 2px 65px",
                              textAlign: "center",
                              wordWrap: "break-word",
                              maxWidth: "100%", // Adjust the maxWidth as needed
                              borderBottom: "solid 2px lightgrey",
                            }}
                            colSpan={7}
                          >
                            <b>{t("kielitaitoarviot.lisätiedot")}:</b>{" "}
                            <span>
                              {assessment.additionalInfo.length <= 20 ? (
                                assessment.additionalInfo
                              ) : (
                                <>
                                  {open[assessment.id] ? (
                                    <span>
                                      {assessment.additionalInfo}{" "}
                                      <span
                                        style={{
                                          color: "blue",
                                          cursor: "pointer",
                                        }}
                                        onClick={(e) =>
                                          setOpen({
                                            ...open,
                                            [assessment.id]: false,
                                          })
                                        }
                                      >
                                        {t("kielitaitoarviot.näytävähemmän")}
                                      </span>
                                    </span>
                                  ) : (
                                    `${assessment.additionalInfo.substring(
                                      0,
                                      20
                                    )}... `
                                  )}
                                  {!open[assessment.id] && (
                                    <span
                                      style={{
                                        color: "blue",
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) =>
                                        setOpen({
                                          ...open,
                                          [assessment.id]: true,
                                        })
                                      }
                                    >
                                      {t("kielitaitoarviot.näytälisää")}
                                    </span>
                                  )}
                                </>
                              )}
                            </span>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "2rem",
              }}
            >
              <LineChart
                width={800} // Adjusted for better visibility
                height={400}
                data={lineData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis
                  type="number"
                  domain={[0, proficiencyLevels.length - 1]}
                  tickFormatter={(index) => proficiencyLevels[index]}
                  ticks={proficiencyLevels.map((_, index) => index)}
                />
                <Tooltip formatter={(value) => numericValueToString(value)} />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="markedAvg"
                  stroke="black"
                  name={t("kielitaitoarviot.yleinen")}
                />
                <Line
                  type="monotone"
                  dataKey="speech"
                  stroke="#8884d8"
                  name={t("kielitaitoarviot.puhuminen")}
                />
                <Line
                  type="monotone"
                  dataKey="speechUnderstanding"
                  stroke="#82ca9d"
                  name={t("kielitaitoarviot.kuullun")}
                />
                <Line
                  type="monotone"
                  dataKey="writing"
                  stroke="#ffc658"
                  name={t("kielitaitoarviot.kirjoittaminen")}
                />
                <Line
                  type="monotone"
                  dataKey="writingUnderstanding"
                  stroke="#ff7300"
                  name={t("kielitaitoarviot.luetun")}
                />
              </LineChart>
            </div>
          </Modal.Body>
        </Modal>
      )}
      <></>


      <Modal
        size="xl"
        className="insModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showReturnModal}
        onHide={() => setShowReturnModal(false)}
      >
        <Modal.Header style={{ alignSelf: "center" }}>
          <Modal.Title>{t("palvelusi.palautusohjeet.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Modal
              size="md"
              className="insModal"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={showReturnBox}
              onHide={() => setShowReturnBox(false)}
            >
              <Modal.Body style={{ alignSelf: "center" }}>
                <h4 style={{ textAlign: "center" }}>
                  {t("palvelusi.palautusohjeet.addCode")}
                </h4>
                <hr />
                <div id="codeInputDiv">
                  <input
                    type="text"
                    id="trackCodeInput"
                    name="trackCode"
                    class="shortInput"
                    onChange={(e) => setReturnCode(e.target.value)}
                  ></input>
                  <br />
                  <br />
                  <Button
                    style={{
                      backgroundColor: "#7db5da",
                      border: "none",
                      color: "black",
                    }}
                    onClick={() => handleReturnCodeSubmit()}
                  >
                    {t("save")}
                  </Button>
                  <br />
                  <br />
                  <p>{t("palvelusi.palautusohjeet.addNotice")}</p>
                </div>
              </Modal.Body>
            </Modal>
            <p id="trueP">
              <p id="warningText"></p>
              {t("palvelusi.palautusohjeet.p1")}
              <br />
              <br />
              <strong>
                &#8226; {t("palvelusi.palautusohjeet.p1o1")}
                <br />
                <br />
                &#8226;&nbsp;{t("palvelusi.palautusohjeet.p1o2")}
                <br />
                <br />
                &#8226;&nbsp;{t("palvelusi.palautusohjeet.p1o3")}
                <br />
                <br />
                &#8226;&nbsp;{t("palvelusi.palautusohjeet.p1o4")}
                <br />
                <br />
                &#8226;&nbsp;{t("palvelusi.palautusohjeet.p1o5")}
                <br />
                <br />
                &#8226;&nbsp;{t("palvelusi.palautusohjeet.p1o6")}
                <br />
                <br />
              </strong>
              {t("palvelusi.palautusohjeet.p2")}
              <br />
              <br />
              {t("palvelusi.palautusohjeet.p3")}
              <br />
              <br />
              &#8226;&nbsp;{t("palvelusi.palautusohjeet.p4")}
              <br />
              <br />
              &#8226;&nbsp;{t("palvelusi.palautusohjeet.p5")}
              <strong id="addressP">{t("palvelusi.palautusohjeet.p6")}</strong>
              {t("palvelusi.palautusohjeet.p7")}
              <br />
              <br />
              &#8226;&nbsp;{t("palvelusi.palautusohjeet.p8")}
              <strong>{t("palvelusi.palautusohjeet.p9")}</strong>{" "}
              {t("palvelusi.palautusohjeet.p10")}
              <strong>{t("palvelusi.palautusohjeet.p11")}</strong>
              <br />
              <br />
              {t("palvelusi.palautusohjeet.p17")}
              <a
                href="https://www.posti.fi/fi/henkiloille/paketit-ja-seuranta/palauta-paketti"
                target="_blank"
              >
                {t("palvelusi.palautusohjeet.p18")}
              </a>
              {t("palvelusi.palautusohjeet.p19")}
              <br />
              <br />
              <div
                id="addBtnDiv"
                style={{ textAlign: "center", height: "4rem" }}
              >
                <Button
                  disabled={showReturnBox ? true : false}
                  onClick={() => setShowReturnBox(true)}
                  style={{
                    backgroundColor: "#7db5da",
                    border: "none",
                    color: "black",
                  }}
                >
                  {t("palvelusi.palautusohjeet.p12")}
                </Button>
              </div>
              {t("palvelusi.palautusohjeet.p13")}
              <br />
              <br />
              {t("palvelusi.palautusohjeet.p14")}
              <br />
              <br />
              Arffman Finland Oy
            </p>
          </>
        </Modal.Body>
      </Modal>

      <Grid
        item
        xs={12}
        sx={{
          height: {
            lg: "200px!important",
            md: "200px!important",
            xs: "100px!important",
          },
        }}
      >
        <Typography
          variant="h2"
          component="h1"
          sx={{
            fontSize: {
              xs: "2.25rem", // for extra-small screens
              md: "3.5rem",
            },
          }}
        >
          {t("palvelusi.title1")}
        </Typography>
        <Typography
          variant="h2"
          component="h1"
          sx={{
            color: "#f28a6c",
            fontSize: {
              xs: "2.25rem", // for extra-small screens
              md: "3.5rem",
            },
          }}
        >
          {t("palvelusi.title2")}
        </Typography>
      </Grid>

      <div style={{ width: "100%", alignSelf: "center" }}>
        {/* Show spinner when loading */}
        {loading && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              marginTop: "27%",
            }}
          >
            <CircularProgress />
          </div>
        )}

        {/* Hide content while loading, fade in when loading completes */}
        <div
          style={{
            opacity: loading ? 0 : 1,
            transition: "opacity 0.5s ease-in",
          }}
        >
          {content}
        </div>
      </div>
    </>
  );
};

export default ServiceInformationGrid;
