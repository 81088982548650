import React from "react";
import { useDropzone } from "react-dropzone";
import CropImg from "../CropImage/CropImg.tsx";
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import '../../i18n';

function DropZone({ onDrop, accept, open, img, setPickedFile, handleSubmit }) {
  const { t, i18n } = useTranslation();
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      accept,
      onDrop,
    });
  const files = acceptedFiles.map((file) => (
      <p>{file.path}</p>
  ));

  console.log("ASDASDSA", img)
  return (
    <div>
      <div {...getRootProps({ className: "dropzone" })}>
        <input className="input-zone" {...getInputProps()} />
        <div className="text-center">
          {isDragActive ? (
            <p className="dropzone-content">
             {t('tiedostot.dropBoxText3')}
            </p>
          ) : (
            <p className="dropzone-content">
               {t('tiedostot.dropBoxText')}
            </p>
          )}
          <button type="button" onClick={open} className="btn">
          {t('tiedostot.dropBoxText2')}
          </button>
        </div>
      </div>

      <aside style={{width: '60%', margin: 'auto', textAlign: '-webkit-center', marginTop: '2rem', marginBottom: '2rem'}}>
        {files}
        {img ? <CropImg img={img} setPickedFile={setPickedFile} handleSubmit={handleSubmit}/> : <></>}

      </aside>
    </div>
  );
}
export default DropZone;