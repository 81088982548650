import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ArrowClockwise } from "react-bootstrap-icons";
import WhatsNew from "../components/WhatsNew/WhatsNew";
import SingleWebinarMonth from "../components/SingleWebinarMonth";
import Container from "@mui/material/Container";
import { Paper, Typography } from "@mui/material";
import { Grid, Divider } from "@mui/material";
import { Box } from "@mui/material";
import WebMaterialCard from "./WebMaterialCard";
import ServiceInformationGrid from "./ServiceInformationGrid";
import CircularProgress from "@mui/material/CircularProgress";
import SingleForm from "../components/Forms/SingleForm";
import { Modal } from "react-bootstrap";
import { useTheme } from "@mui/material/styles";

function WelcomePage({ getUserData, webinars, getWebinars, userData, setUserData, rekryData, setRekryData, projectLinks, setProjectLinks  }) {
  const [allProjects, setAllProjects] = useState(null);
  const [whatsNewState, setWhatsNewState] = useState();
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [guessedEndDate, setGuessedEndDate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [webMaterials, setWebMaterials] = useState(null);
  const [customLoading, setCustomLoading] = useState(false);
  const [webMloading, setWebMloading] = useState(false);
  const { t } = useTranslation();
  const [showProjectSelection, setShowProjectSelection] = useState(false);
  const [projectForms, setProjectForms] = useState([]);
  const [forceAnswers, setForceAnswers] = useState();
  const [showForce, setShowForce] = useState(false);
  const [assessments, setAssessments] = useState(null);
  const theme = useTheme();

  const containerStyle = {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.up("lg")]: {},
  };

  const getForms = () => {
    setLoading(true);
    let obj = {
      projectId: userData?.projectId.value,
      userId: userData?.searchRecId.value,
    };
    fetch(
      "/api/user/getForms/" + sessionStorage.getItem("id"),
      {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        let forceArr = [];
        for (let i = 0; i < data.projectForms.length; i++) {
          let form = data.projectForms[i];

          if (form.forceAnswer && !form.answers) {
            forceArr.push(form);
          }
        }
        if (forceArr.length > 0) {
          setShowForce(true);
        } else {
          setShowForce(false);
        }
        setForceAnswers(forceArr);
        if (!data.projectForms.error) {
          setProjectForms(data.projectForms);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("error: " + error);
        setLoading(false);
      });
  };

  useEffect(() => {
    console.log('laaalaa');
    
    if (userData?.startDate?.value) {
      ProgressCalculator(
        userData?.startDate.value,
        userData?.projectType.value,
        userData?.projectEndDate.value,
        "Aloituspäiväsi on "
      );
    } else if (
      !userData?.startDate.value &&
      userData?.projectStartDate.value
    ) {
      ProgressCalculator(
        userData?.projectStartDate.value,
        userData?.projectType.value,
        userData?.projectEndDate.value,
        "Palvelun aloituspäivä on "
      );
    } else if (
      !userData?.startDate.value &&
      !userData?.projectStartDate.value
    ) {
      const today = new Date(); // Get the current date
      const lastYear = today.getFullYear() - 1; // Get the year of last year
      const janFirstLastYear = new Date(lastYear, 1, -29); // Create a new Date object for January 1st of last year
  
      ProgressCalculator(
        janFirstLastYear.toISOString().split("T")[0],
        userData?.projectType.value,
        userData?.projectEndDate.value,
        "Tarkka aloituspäivä puuttuu. Päiväksi asetettiin "
      );
    }

    getForms();
  }, [userData]);

  const ProgressCalculator = (
    projectStartDate,
    projectType,
    projectEndDate,
    startTitle
  ) => {
    let currentDate = new Date();
    let endDate;
    let startDate;

    if (projectType && projectType === "Valmennus") {
      startDate = new Date(projectStartDate);

      let helpCurrentDate = new Date();

      let helpStartDate = new Date(projectStartDate);
      endDate = new Date(helpStartDate.setMonth(helpStartDate.getMonth() + 3));
      setGuessedEndDate({
        startDate: startDate.toLocaleDateString("fi-FI"),
        endDate: endDate.toLocaleDateString("fi-FI"),
        startTitle: startTitle + startDate.toLocaleDateString("fi-FI"),
      });

      const differenceInMilliseconds = endDate - currentDate;
      const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000;

      if (differenceInMilliseconds >= oneMonthInMilliseconds) {
      } else {
        endDate = new Date(
          helpCurrentDate.setMonth(helpCurrentDate.getMonth() + 1)
        );
      }
    } else if (projectType && projectType !== "Valmennus") {
      startDate = new Date(projectStartDate);
      endDate = new Date(projectEndDate);
      setGuessedEndDate({
        startDate: startDate.toLocaleDateString("fi-FI"),
        endDate: endDate.toLocaleDateString("fi-FI"),
        startTitle: startTitle + startDate.toLocaleDateString("fi-FI"),
      });
    }

    let totalDuration = endDate - startDate;
    let currentDuration = currentDate - startDate;

    let calculateProgress = (currentDuration / totalDuration) * 100;

    if (Math.round(calculateProgress) > 100) {
      setProgress(100);
    } else if (Math.round(calculateProgress) === 0) {
      setProgress(1);
    } else {
      setProgress(Math.round(calculateProgress));
    }
  }

  function handleCardClick(url, item) {
    //url = "https://vm999.edu.arffman.fi"
    let obj = { item };
    const response = fetch(
      "/api/misc/webMaterialClick/" + sessionStorage.getItem("id"),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log(url);
        window.open(url, "_blank");
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  }

  async function getWebMaterials(refresh) {
    setWebMloading(true);
    if (!sessionStorage.getItem("webMaterials") || refresh) {
      setWebMaterials(null);
      await fetch(
        "/api/misc/webmaterials/" + sessionStorage.getItem("id") + "/",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then(function (response) {
          if (response.ok) return response.json();
        })
        .then(async function (data) {
          if (data[0] !== "NO_DATA") {
            sessionStorage.setItem("webMaterials", JSON.stringify(data));
            setWebMaterials(data);
          } else {
            setWebMaterials("NO_DATA");
          }
          setWebMloading(false);
        })
        .catch((error) => {
          console.log("error: " + error);
          setWebMloading(false);
        });
    } else {
      setWebMaterials(JSON.parse(sessionStorage.getItem("webMaterials")));
      setWebMloading(false);
    }
  }

  useEffect(() => {
    getWebMaterials();
  }, []);

  const getAssessments = async (personId, projectId) => {
    const obj = { personId, projectId };
      await fetch(
      "/api/user/getAssessments/" + sessionStorage.getItem("id"),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        data.assessments.sort((a, b) => new Date(b.PVM) - new Date(a.PVM));

        sessionStorage.setItem("assessments", JSON.stringify(data.assessments));
        setAssessments(data.assessments);
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  };

  useEffect(() => {
    //if(!webinars) getWebinars();
  }, []);

  useEffect(() => {
    if (userData) {
      getAssessments(
        userData?.searchRecId.value,
        userData?.projectId.value
      );
    }
    if(!userData) getUserData()
  }, []);

  async function showAllProjects() {
    setLoading(true);
    setShowProjectSelection(true);
    try {
      await getAllProjects();
    } catch (e) {
      console.log("error", e);
    } finally {
      setLoading(false);
    }
  }

  async function getAllProjects() {
    if (sessionStorage.getItem("allprojects"))
      setAllProjects(JSON.parse(sessionStorage.getItem("allprojects")));
    else {
      await fetch(
        "/api/misc/allProjects/" + sessionStorage.getItem("id") + "/",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(),
        }
      )
        .then(function (response) {
          if (!response.ok) throw new Error(JSON.parse(response.body));
          return response.json();
        })
        .then(function (data) {
          let b = JSON.stringify(data);
          sessionStorage.setItem("allprojects", b);
          //console.log(data)
          setAllProjects(data);
        })
        .catch((error) => {
          console.log("error: " + error);
        });
    }
  }

  async function getCustomProject(choice) {
    setWebMloading(true);
    setCustomLoading(true);
    setWebMaterials(null);
    console.log("sdfuhibhibjuibn");
    const obj = { choice };
      await fetch(
      "/api/misc/customUserData/" + sessionStorage.getItem("id") + "/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      }
    )
      .then(function (response) {
        if (!response.ok) throw new Error(JSON.parse(response.body));
        return response.json();
      })
      .then(function (data) {
        console.log(data);

        const handleChange = (userDataA) => {
          setUserData({
            ...userData,
            "projectName": data.projectName,
            "projectLinks": data.projectLinks,
            "projectCity": data.projectCity,
            "projectStartDate": data.projectStartDate,
            "projectEndDate": data.projectEndDate,
            mainTeacher: {
              ...userData?.mainTeacher, // Spread the existing mainTeacher properties
              "name": data.instructor.value.name, // Update the specific name property
              "email": data.instructor.value.email,
              "phone": data.instructor.value.phone,
            },
          });
        };
        handleChange(userData);
        let startDate;
        if (data.projectStartDate) {
          startDate = "Palvelun aloituspäivä on ";
        }
        if (!data.projectStartDate) {
          startDate = "Tarkka aloituspäivä puuttuu. Päiväksi asetettiin ";
        }
        ProgressCalculator(
          data.projectStartDate.value,
          data.serviceType.value,
          data.projectEndDate.value,
          startDate
        );

        setProjectLinks(data.projectLinks);
        setWebMaterials(data.webMaterials);
        setWebMloading(false);
      })
      .catch((error) => {
        setWebMloading(false);
        console.log("error: " + error);
      });
    setCustomLoading(false);
  }

  return (
    <>
      <Container
        sx={{
          ...containerStyle,
          bgcolor: "white",

          zIndex: "0!important",
          minHeight: "85dvh",
          paddingTop: {
            xs: "0px", // for extra-small screens
            md: "27px",
          },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Modal
          size="xl"
          className="deviceModal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showForce}
        >
          <Modal.Header style={{ alignSelf: "center" }}>
            <Modal.Title>Vastattavat lomakkeet</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: "center" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {forceAnswers && (
                <>
                  {forceAnswers.map((item, index) => {
                    //console.log(item.formName, item.answers);
                    return (
                      <>
                        <SingleForm
                          key={item.formName}
                          form={item}
                          refresh={getForms}
                        />
                      </>
                    );
                  })}
                </>
              )}
            </div>
          </Modal.Body>
        </Modal>

        {projectForms && projectForms.some((e) => !e.answers) && (
          <>
            <Paper
              sx={{
                padding: "20px",
                bgcolor: "white",
                borderRadius: "10px",
                width: "95%",
                height: "100%",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              elevation={3}
            >
              {projectForms.map((item, index) => {
                return (
                  <>
                    {item && !item.answers && !item.forceAnswer ? (
                      <>
                        <SingleForm
                          key={item.formName}
                          form={item}
                          refresh={getForms}
                          getUserData={getUserData}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
            </Paper>
          </>
        )}

        <Grid
          container
          spacing={5}
          sx={{
            padding: "30px",
            paddingTop: "0px",
            textAlignLast: "center",
            marginTop: "-5px",
          }}
        >
          <Grid item xs={12} sx={{ paddingTop: "0px!important" }}>
            {whatsNewState ? (
              <>
                <WhatsNew
                  whatsNew={whatsNewState}
                  setWhatsNew={setWhatsNewState}
                  open={open}
                  setOpen={setOpen}
                />
              </>
            ) : (
              <></>
            )}
          </Grid>

          <Grid item xs={12} lg={6}>
            <Paper
              sx={{
                padding: "20px",
                bgcolor: "white",
                borderRadius: "10px",
                width: "100%",
                height: "100%",
              }}
              elevation={3}
            >
              <Grid container>
                <ServiceInformationGrid
                  showAllProjects={showAllProjects}
                  allProjects={allProjects}
                  loading={loading}
                  getCustomProject={getCustomProject}
                  userData={userData}
                  customLoading={customLoading}
                  showProjectSelection={showProjectSelection}
                  setShowProjectSelection={setShowProjectSelection}
                  projectLinks={projectLinks}
                  guessedEndDate={guessedEndDate}
                  progress={progress}
                  webinars={webinars}
                  assessments={assessments}
                />
              </Grid>

              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Grid sx={{ padding: "15px" }} item xs={12}>
                  <Divider
                    variant="middle"
                    sx={{ bgcolor: "secondary.light" }}
                  />
                </Grid>
                <Typography variant="h2" style={{ color: "black!important" }}>
                  {t("home.webinaarit.title")}
                </Typography>

                {webinars ? (
                  <>
                    <div className="webinar-wrapper">
                      {Object.keys(webinars).map((month) => (
                        <>
                          <SingleWebinarMonth
                            key={month}
                            month={month}
                            webinars={webinars}
                          />
                          <Box sx={{ padding: "5px" }}>
                            <Divider variant="middle" />
                          </Box>
                        </>
                      ))}
                    </div>
                  </>
                ) : (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        placeContent: "center",
                        marginTop: "50px",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  </>
                )}
              </Grid>
            </Paper>
          </Grid>

          <Grid
            item
            xs={12}
            lg={6}
            sx={
              {
                //paddingLeft: "10vw!important",
                //paddingRight: "10vw!important",
              }
            }
          >
            <Paper
              sx={{
                padding: "20px",
                bgcolor: "white",
                borderRadius: "10px",
                width: "100%",
                height: "100%",
              }}
              elevation={3}
            >
              <Box
                sx={{
                  display: "flex",
                  marginBottom: "30px",
                  gap: "10px",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    height: {
                      lg: "200px!important",
                      md: "200px!important",
                      xs: "100px!important",
                    },
                  }}
                >
                  <Typography
                    variant="h2"
                    component="h1"
                    sx={{
                      color: "#f28a6c",
                      fontSize: {
                        xs: "2rem", // for extra-small screens
                        md: "3.5rem",
                      },
                    }}
                  >
                    {t("palvelusi.verkkomateriaali.title1")}
                  </Typography>

                  <Typography
                    variant="h2"
                    component="h1"
                    sx={{
                      fontSize: {
                        xs: "1.85rem", // for extra-small screens
                        md: "3.5rem",
                      },
                    }}
                  >
                    {t("palvelusi.verkkomateriaali.title2")}
                  </Typography>
                  <ArrowClockwise
                    onClick={() => getWebMaterials(true)}
                    size={24}
                    style={{
                      marginTop: "10px",
                      cursor: "pointer",
                    }}
                  />
                </Grid>
              </Box>
              <Grid container spacing={2}>
                {webMaterials && webMaterials[0] && !webMloading ? (
                  <>
                    {webMaterials.map((item) => (
                      <WebMaterialCard
                        key={item[0]?.vmId}
                        item={item}
                        handleCardClick={handleCardClick}
                      />
                    ))}
                  </>
                ) : webMloading ? (
                  <Grid item xs={12} sx={{}}>
                    <Box
                      sx={{
                        display: "flex",
                        placeContent: "center",
                        marginTop: "50px",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    {t("home.verkkomateriaali.unavailable")}
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default WelcomePage;
/*


              
*/
