import { React, useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import { CaretDownFill, CaretRightFill } from "react-bootstrap-icons";
import "../i18n";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Button, Divider } from "@mui/material";
import Container from "@mui/material/Container";
import { Paper, Box } from "@mui/material";

function formatDateToFinnishStandard(dateString) {
  const dateObj = new Date(dateString);
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  return new Intl.DateTimeFormat("fi-FI", options)
    .format(dateObj)
    .replace(/\b(?!00\b)0(\d)/g, "$1");
}

function formatTimePlusHours(dateString, hoursToAdd) {
  const dateObj = new Date(dateString);

  // Convert hours to minutes and add to date
  const minutesToAdd = hoursToAdd * 60;
  dateObj.setMinutes(dateObj.getMinutes() + minutesToAdd);

  const options = { hour: "2-digit", minute: "2-digit" };
  return new Intl.DateTimeFormat("fi-FI", options).format(dateObj);
}

const SingleWebinarMonth = ({ month, webinars, prop }) => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const monthNamesInFinnish = {
    1: t("1"),
    2: t("2"),
    3: t("3"),
    4: t("4"),
    5: t("5"),
    6: t("6"),
    7: t("7"),
    8: t("8"),
    9: t("9"),
    10: t("10"),
    11: t("11"),
    12: t("12"),
  };

  return (
    <div key={month} id="single-webinar-month" >
      <Typography
        onClick={handleToggle}
        variant="h4"
        sx={{
          cursor: "pointer",
          color: prop === "etusivu" ? "white" : "black"
        }}
        
      >
        {monthNamesInFinnish[parseInt(month, 10)]}{" "}
        {open ? (
          <>
            <CaretDownFill size={"25"} />
          </>
        ) : (
          <>
            <CaretRightFill size={"25"} />
          </>
        )}
      </Typography>
      
      <Collapse in={open}>
        <Grid container spacing={3} sx={{ marginTop: "24px", color: "black!important", marginBottom:'15px!important'  }}>
          {webinars[month].map((webinar) => {
           //console.log('webianrs:', webinar);
            
            return(

         
            <Grid key={webinar?.signInLink} item xs={6} sx={{ paddingTop: "0px", color: "black!important"}}>
              <Paper
                elevation={3}
                sx={{
                  minHeight: "220px",
                  
                  flexDirection: "column",
                  padding: '5%'
                  
                }}
             
              >
                <Typography sx={{color: "black!important", textAlign: 'center!important'}}>
                  {formatDateToFinnishStandard(webinar.startDate)}{" "}
                  {webinar.length ? (
                    <>
                      - {formatTimePlusHours(webinar.startDate, webinar.length)}
                    </>
                  ) : (
                    <></>
                  )}
                </Typography>
                <Typography
                  sx={{
                    height: "70%",
                    fontWeight: '600',
                    color: "black!important",
                    textAlign: 'center!important'
                  }}
                >
                  {webinar.name}
                </Typography>
                <Box sx={{ marginTop: "auto" }}>
                  <Typography sx={{color: "black!important", textAlign: 'center!important'}}>
                    {webinar.host ? (
                      <>
                        {t("valmentajana")} {webinar.host}
                      </>
                    ) : (
                      <></>
                    )}
                  </Typography>

                  <Button variant="contained" style={{marginTop: '2rem'}}>
                    <a
                      style={{ textDecoration: "none", color: "white" }}
                      href={webinar.signInLink}
                      target="_blank"
                    >
                      {t("ilmottaudu")}
                    </a>
                  </Button>
                </Box>
              </Paper>
            </Grid>
   )})}
        </Grid>
      </Collapse>
    </div>
  );
};

export default SingleWebinarMonth;

/*
 <div className="webinar-single-container" value={webinar} key={webinar}>
                        <strong><p>{formatDateToFinnishStandard(webinar.startDate)} {webinar.length ? <>- {formatTimePlusHours(webinar.startDate, webinar.length)}</> : <></>}</p></strong>
                        <p style={{ fontSize: '1.35rem' }}><strong>{webinar.name}</strong></p>
                        <p style={{ fontSize: '1.1rem' }}>{webinar.host ? <strong>Valmentajana {webinar.host}</strong> : <></>}</p>
                        <a href={webinar.signInLink} target="_blank">Ilmottaudu mukaan tästä</a>
                    </div>
*/
