import { Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import React from "react";

export function PrivateRoute({ children, userConsents }) {
  const location = useLocation();
  const rememberMe = sessionStorage.getItem("goASifkFD4235");
  const consentNeeded = sessionStorage.getItem("consentNeeded")
  const storedConsents = JSON.parse(localStorage.getItem("consents"));

  if(consentNeeded === "true"){
    if (
      location?.pathname === "/palvelusi" &&
      !storedConsents?.palveluntiedot?.id 
    )
      return <Navigate to="/" />;
  
    if (
      location?.pathname === "/hakuprofiili" &&
      !storedConsents?.tyonhakuprofiili?.id
    )
      return <Navigate to="/" />;
  }
 
  if (!rememberMe) {
    return <Navigate to="/" />;
  } else {
    return children;
  }
}
