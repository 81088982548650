import React from "react";
import { Typography } from "@mui/material";
import { Paper } from "@mui/material";
import NoImg from "../images/no-pictures.png";
import { Tooltip } from "react-tippy";

function Links({ link }) {
  return (
    <Tooltip
      theme="dark"
      size="small"
      title={"Avaa linkki: " + link.url}
      position="top"
      trigger="mouseenter"
    >
      <a
        style={{ cursor: "pointer", textDecoration: "none" }}
        href={link.url}
        target="_blank"
        rel="noreferrer"
      >
        <Paper
          elevation={3}
          sx={{
            minHeight: "8.2rem",
            ":hover": {
              boxShadow: 10, // theme.shadows[20]
            },
            textAlign: "center",
            cursor: "pointer",
            padding: "10px",
            borderRadius: "20px",
          }}
        >
          {link.imgUrl ? (
            <>
              <img
                style={{
                  maxWidth: "100%", // Ensures the image is not wider than the container
                  maxHeight: "100%", // Ensures the image is not taller than the container
                  height: "auto", // Maintains the aspect ratio of the image
                  width: "auto", // Maintains the aspect ratio of the image
                  display: "block", // Removes any extra space below the image
                  margin: "0 auto",
                }}
                src={`https://drive.google.com/thumbnail?id=${
                  link.imgUrl.split("/d/")[1].split("/view")[0]
                }`}
              />
            </>
          ) : (
            <>
              <img
                style={{
                  maxWidth: "100%", // Ensures the image is not wider than the container
                  maxHeight: "100%", // Ensures the image is not taller than the container
                  height: "auto", // Maintains the aspect ratio of the image
                  width: "auto", // Maintains the aspect ratio of the image
                  display: "block", // Removes any extra space below the image
                  margin: "0 auto",
                }}
                src={NoImg}
              />
            </>
          )}
          <Typography
            variant="body1"
            sx={{
              fontSize: {
                lg: "1rem",
                md: "1rem",
                xs: "0.6rem",
              },
            }}
          >
            {link.name.length < 24
              ? link.name
              : link.name.substring(0, 21) + "..."}
          </Typography>
        </Paper>
      </a>
    </Tooltip>
  );
}

export default Links;
